import React, {useEffect, useState} from 'react';

import {Button, Typography} from '@mui/material';

import CompareTable from '../components/CompareTable';

const CompareProduct: React.FC = () => {
  const compareProductFromLocalStorage = JSON.parse(localStorage.getItem('compareList') || '[]');
  const [compareList, setCompareList] = useState<any>(compareProductFromLocalStorage);

  const handleRemoveAllProducts = () => {
    setCompareList([]);
    localStorage.setItem('compareList', JSON.stringify(compareList));
  };

  const handleRemoveProduct = (index: any) => {
    const newList = [...compareList];
    newList.splice(index, 1);
    setCompareList(newList);
    localStorage.setItem('compareList', JSON.stringify(compareList));
  };

  useEffect(() => {
    localStorage.setItem('compareList', JSON.stringify(compareList));
  }, [compareList]);

  return (
    <div className='my-10 w-full lg:w-full'>
      <div className='md:mx-28 mb-11 flex flex-col md:flex-row justify-between bg-gray-100 pt-10 items-center'>
        <Typography variant='h5'>Compare Product</Typography>
        <Button onClick={handleRemoveAllProducts}>Remove all products</Button>
      </div>
      <div className='md:mx-28 mb-11'>
        {compareList.length > 0 ? (
          <CompareTable
            data={compareList}
            handleRemoveProduct={handleRemoveProduct}
          />
        ) : (
          <div className='flex justify-center items-center h-screen'>
            <Typography variant='subtitle1'>
              Add product to compare list to start comparing
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompareProduct;
