import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CategoryIcon from "@mui/icons-material/Category";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

type Anchor = "top" | "left" | "bottom" | "right";
type setCategory = {};
type setBrand = {};

interface DrawerComponentProps {
  anchor: Anchor;
  setCategory: setCategory;
  setBrand: setBrand;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  anchor,
  setCategory,
  setBrand,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [checkedCat, setCheckedCat] = useState<any>(null);
  const [checkedBrand, setCheckedBrand] = useState<any>(null);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({
    brands: false,
    categories: true,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryIdFromUrl = searchParams.get("category");
  const brandIdFromUrl = searchParams.get("brand");

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  const handleGetBrandList = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/brands "
      );
      const data = result.data;
      setBrands(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetCategories = async () => {
    try {
      const response = await axios.get(
        "https://goodgamingshop.com/api/v1/categories"
      );
      const data = response?.data.data;
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetCheckedCat = (id: any) => {
    if (checkedCat === id) {
      setCheckedCat(null);
      setCategory(null);
    } else {
      setCheckedCat(id);
      setCategory(id);
    }
  };

  const handleSetCheckedBrand = (id: any) => {
    if (checkedBrand === id) {
      setCheckedBrand(null);
      setBrand(null);
    } else {
      setCheckedBrand(id);
      setBrand(id);
    }
  };

  const handleExpand = (panel: string) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: !prev[panel],
    }));
  };

  useEffect(() => {
    handleGetBrandList();
    handleGetCategories();
    if (categoryIdFromUrl) {
      setCheckedCat(categoryIdFromUrl);
      setExpanded((prev) => ({
        ...prev,
        categories: true,
        brands: false,
      }));
    }
    if (brandIdFromUrl) {
      setCheckedBrand(brandIdFromUrl);
      setExpanded((prev) => ({
        ...prev,
        brands: true,
        categories: false,
      }));
    }
  }, [categoryIdFromUrl, brandIdFromUrl]);

  return (
    <div className="lg:hidden">
      <Button sx={{ color: "#848484" }} onClick={toggleDrawer(true)}>
        <FilterAltIcon /> Filter
      </Button>
      <Drawer anchor={anchor} open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
          }}
          role="presentation"
        >
          <List>
            <ListItem>
              <ListItemIcon>
                <FilterAltIcon />
              </ListItemIcon>
              <ListItemText primary="Filter" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleExpand("categories")}>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Categories" />
                {expanded.categories ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={expanded.categories} timeout="auto" unmountOnExit>
              <div style={{ height: "250px", overflow: "auto" }}>
                <List component="div" disablePadding>
                  {categories.map((category) => (
                    <ListItem key={category.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              parseInt(category.id) === parseInt(checkedCat)
                                ? true
                                : false
                            }
                            onChange={() => handleSetCheckedCat(category.id)}
                          />
                        }
                        label={category.name}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Collapse>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleExpand("brands")}>
                <ListItemIcon>
                  <SportsEsportsIcon />
                </ListItemIcon>
                <ListItemText primary="Brands" />
                {expanded.brands ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={expanded.brands} timeout="auto" unmountOnExit>
              <div style={{ height: "250px", overflow: "auto" }}>
                <List component="div" disablePadding>
                  {brands.map((brand) => (
                    <ListItem key={brand.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              parseInt(brand.id) === parseInt(checkedBrand)
                                ? true
                                : false
                            }
                            onChange={() => handleSetCheckedBrand(brand.id)}
                          />
                        }
                        label={brand.name}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Collapse>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerComponent;
