import React from "react";
import FloatingWhatsAppButton from "../components/FloatingWhatsAppButton";

import aboutUs from "../assets/aboutusphoto.jpeg";
import aboutUs2 from "../assets/aboutfix.webp";
import Image6 from "../assets/brandbaru/image6.webp";
import Image7 from "../assets/brandbaru/image 7.webp";
import Image9 from "../assets/brandbaru/image 9.webp";
import Image10 from "../assets/brandbaru/image 10.webp";
import Image11 from "../assets/brandbaru/image 11.webp";
import Image12 from "../assets/brandbaru/image 12.webp";
import Imagemore from "../assets/brandbaru/More++.webp";
import Wwdpc from "../assets/whatwedo/Group 19.png";
import Wwd2 from "../assets/whatwedo/Group 20.png";
import Wwd3 from "../assets/whatwedo/Group 21.png";
import Wwd4 from "../assets/whatwedo/Group 22.png";
import History1 from "../assets/history/Frame 27-2.png";
import History2 from "../assets/history/Frame 27-1.png";
import History3 from "../assets/history/new1.jpeg";
import History4 from "../assets/history/image 28.png";
import History5 from "../assets/history/Frame 26.png";
import A1 from "../assets/aboutus/1.webp";
import A2 from "../assets/aboutus/2.webp";
import A3 from "../assets/aboutus/3.webp";
import A4 from "../assets/aboutus/4.webp";

import { Typography } from "@mui/material";

const AboutNew: React.FunctionComponent = () => {
  return (
    <div>
      <div className="flex flex-col gap-10 items-center justify-center">
        <div className="mt-10 mb-16 flex flex-col gap-y-8 items-center justify-center lg:w-4/5 rounded-lg lg:mx-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 self-center px-4 gap-y-3 ">
              <h1
                style={{ color: "#9B1B18" }}
                className="text-xl md:text-2xl lg:text-3xl font-roboto"
              >
                GOOD GAMING SHOP
              </h1>
              <h1
                style={{ color: "#1A1460", fontWeight: "bold" }}
                className="mb-4 text-2xl md:text-4xl lg:text-4xl font-roboto"
              >
                Let’s Check About Us
              </h1>
              <h1
                style={{ color: "#000000" }}
                className="mr-6 text-md md:text-l lg:text-l font-montserrat"
              >
                We have become the trust for many customers, to realize customer
                desires in providing solutions and how to find the best gaming
                gear products!
              </h1>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <img
                src={aboutUs2}
                alt=""
                className="rounded-xl px-1 md:rounded-lg"
              />
            </div>
          </div>
          <div className="flex justify-between w-full ">
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image6} alt="Gambar 1" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image7} alt="Gambar 2" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image9} alt="Gambar 3" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image10} alt="Gambar 4" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image11} alt="Gambar 5" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Image12} alt="Gambar 6" className="w-full" />
            </div>
            <div className="flex-auto mx-1 lg:mx-4 self-center">
              <img src={Imagemore} alt="Gambar 7" className="w-full" />
            </div>
          </div>
          <div className="w-full my-6">
            <h1
              style={{
                color: "#1A1460",
                textAlign: "center",
                fontWeight: "bold",
              }}
              className="text-xl md:text-2xl lg:text-3xl font-roboto self-center"
            >
              What We Do
            </h1>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="bg-white rounded-lg shadow-md">
              <img src={A1} alt="Gambar" className="w-full rounded-t-lg" />
              <div className="p-4">
                <h2 className="text-md md:text-l text-[#07164A] text-center font-semibold">PC & Gaming Gear Sales</h2>                
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md">
              <img src={A2} alt="Gambar" className="w-full rounded-t-lg" />
              <div className="p-4">
                <h2 className="text-md md:text-l text-[#07164A] text-center font-semibold">Event Organizer</h2>                
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md">
              <img src={A3} alt="Gambar" className="w-full rounded-t-lg" />
              <div className="p-4">
                <h2 className="text-md md:text-l text-[#07164A] text-center font-semibold">Digital Marketing</h2>                
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md">
              <img src={A4} alt="Gambar" className="w-full rounded-t-lg" />
              <div className="p-4">
                <h2 className="text-md md:text-l text-[#07164A] text-center font-semibold">Community Gathering</h2>                
              </div>
            </div>

          </div>
          <div className="my-6">
            <div className="w-full my-3">
              <h1
                style={{
                  color: "#1A1460",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="text-xl md:text-2xl lg:text-3xl font-roboto self-center"
              >
                Our Vision
              </h1>
            </div>
            <div className="w-full my-3">
              <h1
                style={{ color: "#000000" }}
                className="my-6 text-l md:text-xl lg:text-xl font-montserrat text-center"
              >
                Growing the gaming gear industry in Indonesia by always focusing
                on offering quality products from many gaming gear manufacturers
                around the world in the market and always being the best,
                fastest, and first to provide and introduce complete gaming gear
                to Indonesia.
              </h1>
            </div>
          </div>
          <div className="my-6">
            <div className="w-full my-3">
              <h1
                style={{
                  color: "#1A1460",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="text-xl md:text-2xl lg:text-3xl font-roboto self-center"
              >
                Our History
              </h1>
            </div>
            <div className="w-full my-3">
              <h1
                style={{ color: "#000000" }}
                className="text-l md:text-xl lg:text-xl font-montserrat text-center"
              >
                Kami percaya bahwa passion, evolusi, dan adaptasi adalah kunci
                kesuksesan
              </h1>
            </div>
            <div className="my-5 p-4 border-4 rounded-lg">
              <div className="flex lg:flex-row md:flex-col gap-x-6">
                <div className="md:w-1/5 sm:w-full">
                  <img src={History1} alt="Gambar 1" className="w-full" />
                </div>
                <div className="lg:w-4/5 md:w-full self-center">
                  <h1
                    style={{
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                    className="mb-4 text-l md:text-l lg:text-l font-montserrat"
                  >
                    2010 : Sejarah Dimulai
                  </h1>
                  <h1
                    style={{ color: "#000000", textAlign: "left" }}
                    className="text-l md:text-xl lg:text-xl font-montserrat"
                  >
                    Cerita kami dimulai ketika tahun 2010 di mana Industri
                    Gaming baru mulai berkembang di Indonesia. Pada saat itu,
                    kebutuhan akan Gaming Gear di Indonesia sangat besar, tetapi
                    belum ada toko yang lengkap untuk memenuhi kebutuhan
                    tersebut. Oleh karena itu, pendiri kami, yang merupakan
                    seorang Pro-Gamer pada waktu itu, memiliki ide untuk
                    mendirikan sebuah toko Gaming Gear spesialis yang
                    menyediakan koleksi Gaming Gear terlengkap untuk memenuhi
                    kebutuhan para gamer.
                  </h1>
                </div>
              </div>
            </div>
            <div className="my-5 p-4 border-4 rounded-lg">
              <div className="flex grid grid-cols-5 gap-x-6">
                <div className="col-span-1">
                  <img src={History2} alt="Gambar 1" className="w-full" />
                </div>
                <div className="col-span-4 self-center">
                  <h1
                    style={{
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                    className="mb-4 text-l md:text-l lg:text-l font-montserrat"
                  >
                    2016 : Focus Dan Konsisten
                  </h1>
                  <h1
                    style={{ color: "#000000", textAlign: "left" }}
                    className="text-l md:text-xl lg:text-xl font-montserrat"
                  >
                    Focus kami tidak pernah berubah yaitu menyediakan gaming
                    gear berkualitas dari tahun ke tahun , dari tahun 2012
                    sampai 2016 kita membuka store baru di Mall Mangga dua ,
                    Banyak rintangan tetapi kekonsistensi selalu membuat
                    customer kami puas dan senang dengan pelayanan kami yang
                    terjamin . DI tahun 2016 di Store ini juga kita membuka
                    Divisi Baru yaitu service center yang bisa mereparasi gaming
                    gear customer kami yang rusak walaupun sudah habis masa
                    garansi . Bukan hanya menjadi toko tetapi menjadi satu
                    perhentian dimana customer bisa membeli dan juga mereparasi
                    gaming gear yang rusak
                  </h1>
                </div>
              </div>
            </div>

            <div className="my-5 p-4 border-4 rounded-lg">
              <div className="flex grid grid-cols-5 gap-x-6">
                <div className="col-span-1">
                  <img src={History3} alt="Gambar 1" className="w-full" />
                </div>
                <div className="col-span-4 self-center">
                  <h1
                    style={{
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                    className="mb-4 text-l md:text-l lg:text-l font-montserrat"
                  >
                    2021 : Bertahan , Berjuang , Berkembang
                  </h1>
                  <h1
                    style={{ color: "#000000", textAlign: "left" }}
                    className="text-l md:text-xl lg:text-xl font-montserrat"
                  >
                    Bertahan dan berjuang di tengah pandemi kita tetap
                    berkembang dan mewujudkan mimpi kami membuka Experience Zone
                    yang proper untuk customer kami . Disaat lampau kita sering
                    kali kita mengikuti pameran, tetapi kita berpikir kenapa
                    Gaming gear hanya bisa di rasakan disaat pameran event saja
                    , dari ide itu kita membuat Gaming Gear Offline Experience
                    zone pertama di Indonesia . Dimana user bisa merasakan
                    pameran gaming gear dari banyak merek , meng-experience
                    gaming gear dari banyak merek secara langsung tanpa harus
                    menunggu waktu pameran untuk menikmati dan mencoba gaming
                    gear berkualitas . Coba dulu sebelum membeli adalah
                    kata-kata yang selalu kita tekankan kepada customer loyal
                    kami , karena Gaming gear sangat banyak dan juga kebutuhan
                    setiap customer berbeda maka dari itu kita mau tempat ini
                    menjadi tempat untuk customer kita mau untuk mencoba ,
                    bercengkrama , berdiskusi dahulu sebelum membeli . Gaming
                    Brand harus bisa mengikuti kebutuhan gaming users , bukan
                    user yang harus mengikuti kebutuhan gaming gear yang sudah
                    ada dan kami percaya bahwa dari sekian banyak brand gaming
                    Gear yang ada pasti ada satu yang lebih cocok di antara yang
                    lain untuk Customer setia kita
                  </h1>
                </div>
              </div>
            </div>
            <div className="my-5 p-4 border-4 rounded-lg">
              <div className="flex grid grid-cols-5 gap-x-6">
                <div className="col-span-1">
                  <img src={History5} alt="Gambar 1" className="w-full" />
                </div>
                <div className="col-span-4 self-center">
                  <h1
                    style={{
                      color: "#000000",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                    className="mb-4 text-l md:text-l lg:text-l font-montserrat"
                  >
                    2022-2023 : Evolusi , Adapatasi , Konsistensi
                  </h1>
                  <h1
                    style={{ color: "#000000", textAlign: "left" }}
                    className="text-l md:text-xl lg:text-xl font-montserrat"
                  >
                    Berevolusi dan beradaptasi dengan konsisten di market , Di
                    Tahun 2022 kita menyadari bahwa kebutuhan Gaming gear Di
                    Indonesia telah shifting ke arah lifestyle yang membuat
                    demand gaming gear sedikit berbeda dengan awal pertama kali
                    GOODGAMINGSHOP didirikan . Kebutuhan Keyboard yang dahulu
                    hanya di prioritaskan untuk gaming , tapi sekarang mulai
                    shifting ke arah lifestyle dan juga kerja , Maka dari itu di
                    tahun 2022 kita membuat divisi Custom mechanical Keyboard ,
                    Yang kita namakan KEEB CORNER ( "KEEB" = Keyboard ) . Dengan
                    adanya Divisi Baru ini juga di tahun 2023 membuat kita
                    membuka cabang ketiga kita di Daerah serpong , yang punya 1
                    lantai khusus untuk custom mechanical keyboard , dimana
                    customer setia kita bisa langsung datang ke Keeb corner
                    untuk mencoba barang baru , switch baru , barebone ,
                    keyboard , dan juga banyak aksesoris custom mechanical
                    keyboard secara langsung tidak hanya online dan menerka
                    nerka rasa dari switch dan aksesoris yang terdiri dari
                    banyak sekali rasa yang disediakan para penyedia Brand .
                    Semoga dengan adanya Divisi baru KEEB CORNER ini Dunia
                    Gaming Gear di Indonesia akan selalu berkembang
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="my-6">
            <div className="w-full my-3">
              <h1
                style={{
                  color: "#1A1460",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="text-xl md:text-2xl lg:text-3xl font-roboto self-center"
              >
                What People Say About Good Gaming Shop
              </h1>
            </div>
            <div className="w-full my-3">
              <h1
                style={{ color: "#000000" }}
                className="text-l md:text-xl lg:text-xl font-montserrat text-center"
              >
                Gamers Speak Out: Unveiling the Unparalleled Quality and
                Excitement of Our Gaming Stuff!
              </h1>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div className="relative pb-[56.25%] h-0 overflow-hidden">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/7eCb0OBy0aY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="relative pb-[56.25%] h-0 overflow-hidden">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/sHZii3kh6x0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="relative pb-[56.25%] h-0 overflow-hidden">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/NkCfyYraLmI"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default AboutNew;
