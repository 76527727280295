import React from "react";

import { Typography } from "@mui/material";

const Faq: React.FunctionComponent = () => {
  return (
    <div className="flex flex-col gap-10 items-center justify-center">
      <div className="mt-10 mb-16 flex flex-col gap-y-8 lg:flex-row items-center justify-center lg:w-4/5 rounded-lg lg:mx-4">
        <div className="w-full p-6 lg:p-24 flex flex-col gap-y-8">
          <Typography variant="h4">FAQ</Typography>
          <Typography variant="h6" sx={{ color: "#606060" }} align="justify">
            <span className="font-bold">
              {" "}
              Q : Dimana sih lokasi Offline Store GOODGAMINGSHOP ?
            </span>
            <br />
            A : Toko offline GOODGAMINGSHOP ada di dua tempat, yaitu di Ruko
            Mangga Dua Mall no.45 A, dan di Ruko Pisa Grande Paramount F/7
            Gading Serpong.
            <br />
            <br />
            <span className="font-bold">
              Q : Apakah bisa beli lewat online juga?{" "}
            </span>
            <br />
            A : Tentu saja bisa. Kamu bisa beli gaming gear yang kamu butuhkan
            di GGS via Website, Marketplace (Tokopedia, Shopee, dll), dan atau
            via chat lewat DM Whatsapp ke nomer 0812 – 93 – 665 – 665, atau
            melalui DM Instagram.
            <br />
            <br />
            <span className="font-bold">
              Q : Apakah stock yang ada di marketplace sama dengan stock yang
              ada di toko?{" "}
            </span>
            <br />
            A : Stock yang ada di toko sama persis dengan yang ada di
            marketplace, hanya saja terkadang ada keterlambatan pengiriman
            sehingga membuat stocknya menjadi berbeda. Untuk lebih amannya, kamu
            bisa chat ke admin marketplace-nya sebelum meng-order ya.
            <br />
            <br />
            <span className="font-bold">
              Q : Apakah bisa beli lewat online juga?{" "}
            </span>
            <br />
            A : Tentu saja bisa. Kamu bisa beli gaming gear yang kamu butuhkan
            di GGS via Website, Marketplace (Tokopedia, Shopee, dll), dan atau
            via chat lewat DM Whatsapp ke nomer 0812 – 63 – 665 – 665, atau
            melalui DM Instagram.
            <br />
            <br />
            <span className="font-bold">
              Q : Kapan saja jam operasional GOODGAMINGSHOP? Baik Offline Store
              dan Online Store?{" "}
            </span>
            <br />
            A : Untuk Offline Store berikut jam operasionalnya :
            <br />
            <ul>
              <li>
                - GOODGAMINGSHOP EXPERIENCE ZONE - Ruko Mangga Dua Mall no. 45 A
                : 10.00 – 18.00 WIB
              </li>
              <li>
                - GOODGAMINGSHOP x KEEB CORNER – Ruko Pisa Grande Blok F/7 :
                12.00 – 20.00 WIB
              </li>
              Adapun jam operasional dari admin online kami mengikuti jam
              operasional dari GOODGAMINGSHOP EXPERIENCE ZONE ya.
            </ul>
            <br />
            <span className="font-bold">
              Q : Apakah bisa melakukan pengiriman Instan dan Same Day?{" "}
            </span>
            <br />A : Pengiriman Same Day hanya bisa dilakukan jika kamu tinggal
            di area Jakarta dan sekitarnya, pada pukul 11.00 – 17.00 WIB.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Faq;
