import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";
import Logoggs1 from "../assets/whitelogo.png";
import Fb from "../assets/logosocmed/Vector-1.webp";
import Ig from "../assets/logosocmed/Vector.webp";

import {
  Phone,
  Place,
  AccessTime,
  Facebook,
  Instagram,
  Twitter,
} from "@mui/icons-material";

// // import { LineIcon } from "../assets/socialMedia";

// // const socialMedia = [
// //   {
// //     icon: Facebook,
// //     link: "https://www.facebook.com/your-page",
// //   },
// //   {
// //     icon: Instagram,
// //     link: "https://www.instagram.com/your-page",
// //   },
// //   {
// //     icon: Twitter,
// //     link: "https://www.twitter.com/your-page",
// //   },
// //   {
// //     icon: LineIcon,
// //     link: "https://www.twitter.com/your-page",
// //   },
// // ];

const currentYear = new Date().getFullYear();

const NewFooter: React.FunctionComponent = () => {
  return (
    <div className="flex flex-col mt-8">
      <div className="flex flex-col md:flex-row bg-primary-100 text-white border-t border-[#C0C0C0] ">
        <div className="w-full md:w-1/4 px-12 py-8 ">
          <div className="flex flex-col mb-4">
            <div className="w-3/4 mb-6">
              <img src={Logoggs1} alt="" className="object-contain mx-auto" />
            </div>
            <div className="flex mb-4">
              <Place />
              <p  className="mb-5">
                Ruko Mangga 2 Mall No.45A Ruko Mangga Dua Mall, Deretan Bank
                Panin, RT.1 / RW.12, South Mangga Dua, Sawah Besar, Jakarta
                10730
              </p>
            </div>
          </div>
          <div className="flex mb-4">
            <Phone />
            <p>(021) 62302218 </p>
          </div>
          <div className="flex mb-3 ">
            <AccessTime />
            <p>Mon – Sat: 10am To 6pm </p>
          </div>
        </div>
        <div className="w-full md:w-1/4 px-12 py-8">
          <p className="mb-6 font-bold" >
            Our Services
          </p>
          <div className="flex flex-col gap-y-4 mt-6">
            <Link href="/about-us-2" color="inherit" underline="hover">
              About Us
            </Link>
            <Link href="/contact-us" color="inherit" underline="hover">
              Contact Us
            </Link>
            <Link href="/faq" color="inherit" underline="hover">
              FAQ
            </Link>
            <Link href="/return-and-warranty" color="inherit" underline="hover">
              Return and Warranty
            </Link>
            <Link href="https://drive.google.com/drive/folders/1TmDx070XxYC6m1Oki3OteFXbpwxSi7Ye?usp=sharing" color="inherit" underline="hover">
              Driver Download
            </Link>
          </div>
        </div>
        <div className="w-full md:w-1/4 px-12 py-8">
          <p className="mb-6 font-bold" >
            Our Showroom
          </p>
          <div className="flex flex-col gap-y-4 mt-8">
            <p>
              Ruko Mangga Dua Mall Nomer 45A, Jakarta Pusat Working Hour ( 10.00
              – 18.00 )
            </p>
            <p>
              Ruko Pisa Grande Paramount F/7, Tangerang Working Hour ( 12.00 –
              20.00 ){" "}
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/4 px-12 py-8">
          <p className="font-bold">Our Social Media</p>
          <div className="flex flex-col gap-2 mb-2">
            <div className=" flex flex-row h-8">
              <a
                href="https://www.facebook.com/GOODGAMINGSHOP/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Fb} alt="" className="h-full" />
              </a>
              <a
                href="https://www.instagram.com/goodgamingshop/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Ig} alt="" className="ml-4 h-full" />
              </a>
            </div>
          </div>
          <p className="font-bold">Other Social Media</p>
          <div className="flex flex-col gap-2">
            <Link
              href="https://www.instagram.com/keebcorner/"
              color="inherit"
              underline="hover"
            >
              KeebCorner
            </Link>
            <Link
              href="https://www.instagram.com/ggsconsign/"
              color="inherit"
              underline="hover"
            >
              GGS Consign
            </Link>
          </div>
        </div>
      </div>
      <div className=" text-center border-t-1 border-white py-4">
        <p className="text-primary-100 text-sm md:text-md mr-2">© 2010-2024, <span className="font-bold text-primary-200">GoodGamingShop</span>  by CV. Good Game</p>

      </div>
    </div>
  );
};

export default NewFooter;
