import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import {Typography} from '@mui/material';
import {ArrowForwardIos, ArrowBackIos} from '@mui/icons-material';

const Card = ({brand}: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/products?brand=${brand.id}`);
  };
  return (
    <div
      className=' px-1 md:px-4 flex md:mb-0  hover:border-gray-300 hover:cursor-pointer items-center justify-center rounded-md'
      onClick={handleClick}
    >
      <img
        src={brand?.image}
        alt={'brand'}
      />
    </div>
  );
};

const BrandSlider = () => {
  const [brandList, setBrandList] = useState<any[]>([]);
  const [page, setPage] = useState({begin: 0, end: 6});

  const handleGetBrandList = async () => {
    try {
      const result = await axios.get('https://goodgamingshop.com/api/v1/brands ');
      const data = result.data;
      setBrandList(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetNextPage = () => {
    if (page.end <= brandList.length) {
      setPage({begin: page.begin + 6, end: page.end + 6});
    }
  };

  const handleGetPrevPage = () => {
    if (page.begin > 0) {
      setPage({begin: page.begin - 6, end: page.end - 6});
    }
  };

  useEffect(() => {
    handleGetBrandList();
  }, []);

  return (
    <div className='flex flex-col my-2 items-center justify-center w-4/5  rounded-lg'>
      <div className='flex items-center justify-between w-full px-4'>
        
      </div>
      <div className='flex items-center px-2'>
        <div
          onClick={handleGetPrevPage}
          className={page.begin === 0 ? 'hidden' : ''}
        >
          <ArrowBackIos />
        </div>
        <div className='grid grid-cols-3  md:grid-cols-5 lg:grid-cols-6 px-4 md:gap-2  gap-1'>
          {brandList
            .filter((item, index) => page.begin <= index && index < page.end)
            .map((brand) => {
              return <Card brand={brand} />;
            })}
        </div>
        <div
          onClick={handleGetNextPage}
          className={page.end >= brandList.length ? 'hidden' : ''}
        >
          <ArrowForwardIos />
        </div>
      </div>
    </div>
  );
};

export default BrandSlider;
