/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, {useEffect, useState} from 'react';

import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import {styled} from '@mui/material/styles';
import {IconButton, Button} from '@mui/material';
import {AddShoppingCart, CompareArrows} from '@mui/icons-material';

import Spinner from '../components/Spinner';
import DenseTable from '../components/Table';
import ProductCarousel from '../components/ProductCarousel';
import {useSnackbar} from 'notistack';

import currencyFormat from '../helper/currencyFormat';

import {shopee, whatsapp, tokopedia, bukalapak} from '../assets/eCommerceIcon';
import {useParams} from 'react-router-dom';

type ProductType = {
  id: number;
  name: string;
  SKU: string;
  price: number;
  final_price: number;
  initial_price: number;
  discount: number;
  tokopedia_link: string;
  shopee_link: string;
  bukalapak_link: string;
  description: string;
  brand: {name: string};
  stok: string;
  stapre: string;
  specification: {
    id: number;
    json_data: {};
  };
  category: {
    id: number;
    name: string;
    specification: any[];
  };
  subcategory: {
    id: number;
    name: string;
    category_id: number;
  };
  images: [
    {
      id: number;
      url: string;
      product_id: number;
    },
  ];
  variants: {
    id: number;
    variant_data: [
      {
        final_price: string;
        initial_price: string;
        name: string;
        price: number;
      },
    ];
  };
};
const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  // backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
    color: 'white',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0069d9',
    // borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    backgroundColor: '#0069d9',
    color: 'white',
  },
});

const initialState: ProductType = {
  id: 15,
  name: 'test 10',
  stok: 'ada',
  stapre: 'non',
  SKU: '123',
  price: 30000,
  initial_price: 30000,
  final_price: 1460000,
  discount: 0,
  tokopedia_link: 'https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition',
  shopee_link: 'https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition',
  bukalapak_link: 'https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition',
  brand: {name: ''},
  description: '',
  specification: {
    id: 16,
    json_data: {
      tipe: 'tipe',
      model: 'model',
      socket: 'socke',
    },
  },
  category: {
    id: 11,
    name: 'VGA',
    specification: ['model', 'tipe', 'socket'],
  },
  subcategory: {
    id: 2,
    name: 'AMD',
    category_id: 11,
  },
  images: [
    {
      id: 7,
      url: 'https://storage.googleapis.com/ggs-dewa-ruci/aea4a2d0-8088-4e5c-8323-f023c8acc2bc.webp',
      product_id: 15,
    },
  ],
  variants: {
    id: 16,
    variant_data: [
      {
        final_price: '1.986.000',
        initial_price: '2.648.000',
        name: 'Black',
        price: 1986000,
      },
    ],
  },
};

const Product: React.FunctionComponent = () => {
  const [prices, setPrices] = useState('');
  const [quantity, setQuantity] = useState<any>(1);
  const [variant, setVariant] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [activeVariant, setActiveVariant] = useState<any>(0);
  const [product, setProduct] = useState<ProductType>(initialState);
  const [variantIndex, setVariantIndex] = useState<number>(0);
  const [stok, setStok] = useState<string>();
  const [stapre, setStapre] = useState<string>();

  const {id} = useParams();

  const CartFromLocalStorage = JSON.parse(localStorage.getItem('cart') || '[]');
  const [cart, setCart] = useState<any>(CartFromLocalStorage);

  const CompareListFromLocalStorage = JSON.parse(localStorage.getItem('compareList') || '[]');
  const [compareList, setCompareList] = useState<any>(CompareListFromLocalStorage);

  const waLink = product.name.split(' ').join('%20');
  const waUrl = `https://wa.me/6281293665665?text=Halo%20Goodgamingshop%2Csaya%20ingin%20memesan%20(${waLink})`;
  const {enqueueSnackbar} = useSnackbar();

  const handleGetProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://goodgamingshop.com/api/v1/product/${id}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
      const data = response.data.data;
      setProduct(data);
      setStok(data.stok); // Setel nilai "stok" dari data produk
    setStapre(data.stapre);
      setVariant(data.variants.variant_data[0].name);
      setPrices(data.variants.variant_data[0].price);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetprices = (index: any) => {
    setPrices(product.variants.variant_data[index].final_price);
    setVariant(product.variants.variant_data[index].name);
    setActiveVariant(index);
    setVariantIndex(index);
  };

  const handleGetDescriptionText = (text: string) => {
    return text.replace('/\n/g', '//n');
  };

  const handleCart = (id: any) => {
    enqueueSnackbar('Added to Cart', {variant: 'success'});
    if (cart.filter((item: any) => item.id === id && variant === item.variant).length) {
      const filteredCart = cart.filter((item: any) => item.id === id && variant === item.variant);
      const index = cart.findIndex((item: any) => {
        return item.id === id && variant === item.variant;
      });
      filteredCart[0].quantity += quantity;
      cart.splice(index, 1, filteredCart[0]);
      return setCart([...cart]);
    }
    if (cart.length === 0) {
      cart.push({
        id: product.id,
        name: product.name,
        variant: variant,
        discount: product.discount,
        initialPrice: product.variants.variant_data[variantIndex].initial_price,
        finalPrice: product.variants.variant_data[variantIndex].price,
        image: product.images[0],
        quantity: quantity,
      });
    } else {
      setCart([
        ...cart,
        {
          id: product.id,
          name: product.name,
          variant: variant,
          discount: product.discount,
          initialPrice: product.variants.variant_data[variantIndex].initial_price,
          finalPrice: product.variants.variant_data[variantIndex].price,
          image: product.images[0],
          quantity: quantity,
        },
      ]);
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  const handleCompareList = (id: any) => {
    if (compareList.length > 0 && product.category.id !== compareList[0].categoryId) {
      return enqueueSnackbar('Cannot compare product from different category', {
        variant: 'error',
      });
    } else if (
      compareList.length > 0 &&
      compareList.filter((item: any) => item.id === product.id).length > 0
    ) {
      return enqueueSnackbar('Product already in the compare list', {
        variant: 'error',
      });
    } else if (compareList.length === 4) {
      return enqueueSnackbar('Your compare list is full', {
        variant: 'error',
      });
    }

    enqueueSnackbar('Added to Compare List', {variant: 'success'});
    if (compareList.filter((item: any) => item.id === id && variant === item.variant).length) {
      const filteredCompareList = compareList.filter(
        (item: any) => item.id === id && variant === item.variant,
      );
      const index = compareList.findIndex((item: any) => {
        return item.id === id && variant === item.variant;
      });
      filteredCompareList[0].quantity += quantity;
      compareList.splice(index, 1, filteredCompareList[0]);
      return setCompareList([...compareList]);
    }
    if (compareList.length === 0) {
      compareList.push({
        id: product.id,
        name: product.name,
        finalPrice: product.variants.variant_data[variantIndex].price,
        image: product.images[0],
        category: product.category.name,
        categoryId: product.category.id,
        brand: product.brand.name,
        specification: product.specification.json_data,
      });
    } else {
      setCompareList([
        ...compareList,
        {
          id: product.id,
          name: product.name,
          finalPrice: product.variants.variant_data[variantIndex].price,
          image: product.images[0],
          category: product.category.name,
          categoryId: product.category.id,
          brand: product.brand.name,
          specification: product.specification.json_data,
        },
      ]);
    }
    localStorage.setItem('compareList', JSON.stringify(compareList));
  };

  useEffect(() => {
    handleGetProduct();
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('compareList', JSON.stringify(compareList));
  }, [compareList]);

  console.log(Object.keys(product.specification.json_data).length);

  return (
    <div>
    <div className='md:flex items-center justify-center bg-white'>
      {loading ? (
        <div className='m-24'>
          <Spinner styles='h-48 w-48' />
        </div>
      ) : (
        <div className='md:flex  justify-center gap-10 shadow-md p-4'>
          <div className='md:w-1/4 h-fit md:mb-2 md:flex md:flex-col gap-2 md:flex-wrap items-center justify-center rounded-md'>
            {product.images.length > 1 && <ProductCarousel productImage={product.images} />}
            <div
              className='mt-3 border items-center w-full justify-center cursor-pointer py-2 flex bg-info-20 rounded'
              onClick={() => handleCompareList(product.id)}
            >
              <CompareArrows sx={{color: 'white'}} />
              <p className='text-white text-base font-normal ml-2 '>Compare product</p>
            </div>
          </div>
          <div
            className={`md:w-2/5 w-full ${
              Object.keys(product.specification.json_data).length === 0 ? 'px-4' : ''
            } `}
          >
            <h1 className=' text-secondary-10 pb-2 text-base md:text-2xl font-medium'>
              {product.name}
            </h1>
            {product.discount ? (
              <div className='flex'>
                <div className='bg-info-20 p-1 rounded-sm text-xs w-fit text-white flex'>
                  {product.discount}%
                </div>
                <span className='text-primary-10 ml-2'>
                  <s>Rp. {product.initial_price}</s>
                </span>
              </div>
            ) : (
              ''
            )}
            {product.variants.variant_data.length <= 1 ? (
              <h2 className='mt-4 text-info-20 font-bold text-base md:text-3xl'>
                Rp. {product.variants.variant_data[0].final_price}
              </h2>
            ) : (
              <div>
                <h2 className='mt-4 text-info-20 font-bold text-base md:text-3xl'>
                  Rp. {prices === '' ? product.variants.variant_data[0].final_price : prices}
                </h2>
                <h4 className='text-slate-500 font-medium text-base mt-6'>Pilih Varian:</h4>
                <div className='grid grid-cols-2 md:flex gap-4 mt-4 overflow-auto'>
                  {product.variants.variant_data.map((item, index) => {
                    return (
                      <BootstrapButton
                        variant='outlined'
                        disableRipple
                        sx={{
                          boxShadow:
                            index === activeVariant ? '0 0 0 0.2rem rgba(0,123,255,.5)' : '',
                          backgroundColor: index === activeVariant ? '#0069d9' : '',
                          color: index === activeVariant ? 'white' : '',
                        }}
                        onClick={() => handleSetprices(index)}
                      >
                        {item.name}
                      </BootstrapButton>
                    );
                  })}
                </div>
              </div>
            )}
            <div className='flex justify-between my-10'>
              <div>
              {product.stapre === "preorder" ? (
  <h4 className='text-red-800 font-bold text-base'>
    Barang ini adalah produk Pre-Order <br/> Silahkan hubungi Whatsapp Kami Untuk Melakukan Preorder
  </h4>
) : null}
                <h4 className='text-slate-500 font-medium text-base'>SKU: {product.SKU}</h4>
                <h4 className='text-slate-500 font-medium text-base'>
                  Kategori: {product.category.name}
                </h4>
                

                <h4 className='text-slate-500 font-medium text-base'>
                  Brand: {product.brand.name}
                </h4>
              </div>
            </div>
            <div className='mb-4'>
              <h4 className='text-slate-500 font-medium text-base'>Deskripsi:</h4>
              <p className='text-slate-500 font-medium text-base text-justify'>
                {handleGetDescriptionText(product.description)}
              </p>
            </div>
            <div>
              {Object.keys(product.specification.json_data).length > 0 && (
                <DenseTable data={product.specification.json_data} />
              )}
            </div>
          </div>
          <div className='md:w-1/5 w-full h-fit mb-2 items-center justify-center rounded-md border p-3'>
            <h2 className='font-medium text-xl text-secondary-10'>Belanja Sekarang</h2>
            <p className='text-primary-10 text-base font-normal'>
              {product.variants.variant_data.length <= 1 ? '' : `Varian: ${variant}${' '}`}
            </p>
            <hr className='my-4' />
            <div className='flex items-center'>
              <p className='text-primary-10 text-base font-normal flex-1'>Jumlah</p>
              <div className='flex items-center border-gray-100 mr-7'>
                <span
                  className={`${
                    quantity < 0 ? 'hidden' : ''
                  } cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50`}
                  onClick={() => setQuantity(quantity - 1)}
                >
                  -
                </span>
                <input
                  className='h-8 w-8 border bg-white text-center text-xs outline-none'
                  type='quantity'
                  value={quantity < 0 ? 0 : quantity}
                  minLength={0}
                />
                <span
                  className='cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50'
                  onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </span>
              </div>
            </div>
            <div className='flex items-center mt-3'>
              <p className='text-primary-10 text-base font-normal flex-1'>Total</p>
              <p className='text-secondary-10 text-base font-semibold mr-7'>
                {currencyFormat(
                  product.variants.variant_data[variantIndex].price * (quantity < 0 ? 0 : quantity),
                )}
              </p>
            </div>
            
            {product.stapre === "non" ? (
  product.stok === "tidak" ? (
    <div className='mt-3 text-center text-red-500 font-semibold'>
      Stock Habis
    </div>
  ) : (
    <div
      className='mt-3 border items-center justify-center py-2 flex bg-info-20 rounded cursor-pointer'
      onClick={() => handleCart(product.id)}
    >
      <AddShoppingCart sx={{color: 'white'}} />
      <p className='text-white text-base font-normal ml-2'>Add to cart</p>
    </div>
  )
) : (
  <h4 className='text-red-800 font-bold text-base'>
    Barang ini adalah produk Pre-Order <br/> Silahkan hubungi Whatsapp Kami Untuk Melakukan Preorder
  </h4>
)}


            <hr className='my-4' />
            <div className='md:flex md:flex-col md:w-1/2 w-full justify-center items-center'>
              <h4 className='text-slate-500 font-medium text-base'>Tersedia di Marketplace:</h4>
              <div className='md:flex w-full'>
                <IconButton>
                  <a
                    href={waUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      width='30px'
                      src={whatsapp}
                      alt='whatsapp'
                    />
                  </a>
                </IconButton>
                {product.tokopedia_link && (
                  <IconButton>
                    <a
                      href={product.tokopedia_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        width='30px'
                        src={tokopedia}
                        alt='tokopedia'
                      />
                    </a>
                  </IconButton>
                )}
                {product.bukalapak_link && (
                  <IconButton>
                    <a
                      href={product.bukalapak_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        width='30px'
                        src={bukalapak}
                        alt='bukalapak'
                      />
                    </a>
                  </IconButton>
                )}
                {product.shopee_link && (
                  <IconButton>
                    <a
                      href={product.shopee_link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        width='30px'
                        src={shopee}
                        alt='shopee'
                      />
                    </a>
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    <FloatingWhatsAppButton /> 
    </div>
  );
};

export default Product;
