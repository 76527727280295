import React, { useEffect, useState } from "react";

import {
  FormControlLabel,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Filters = ({ setCategory, setBrand }: any) => {
  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [checkedCat, setCheckedCat] = useState<any>(null);
  const [checkedBrand, setCheckedBrand] = useState<any>(null);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({
    brands: false,
    categories: true,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryIdFromUrl = searchParams.get("category");
  const brandIdFromUrl = searchParams.get("brand");

  const handleGetBrandList = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/brands "
      );
      const data = result.data;
      setBrands(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetCategories = async () => {
    try {
      const response = await axios.get(
        "https://goodgamingshop.com/api/v1/categories"
      );
      const data = response?.data.data;
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetCheckedCat = (id: any) => {
    if (checkedCat === id) {
      setCheckedCat(null);
      setCategory(null);
    } else {
      setCheckedCat(id);
      setCategory(id);
    }
  };

  const handleSetCheckedBrand = (id: any) => {
    if (checkedBrand === id) {
      setCheckedBrand(null);
      setBrand(null);
    } else {
      setCheckedBrand(id);
      setBrand(id);
    }
  };

  const handleExpand = (panel: string) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: !prev[panel],
    }));
  };

  useEffect(() => {
    handleGetBrandList();
    handleGetCategories();
    if (categoryIdFromUrl) {
      setCheckedCat(categoryIdFromUrl);
      setExpanded((prev) => ({
        ...prev,
        categories: true,
        brands: false,
      }));
    }
    if (brandIdFromUrl) {
      setCheckedBrand(brandIdFromUrl);
      setExpanded((prev) => ({
        ...prev,
        brands: true,
        categories: false,
      }));
    }
  }, [categoryIdFromUrl, brandIdFromUrl]);

  return (
    <div className="hidden flex-col border-2 border-t-blue-900 rounded-lg p-4 sticky mb-4 mt-10 w-1/5 top-6z md:min-w-fit lg:flex">
      <h1 className="mb-6 font-semibold border-b-2 text-xl">Filter</h1>
      <div>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExpand("categories")}>
              <ListItemText primary="Categories" />
              {expanded.categories ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={expanded.categories} timeout="auto" unmountOnExit>
            <div style={{ height: "250px", overflow: "auto" }}>
              <List component="div" disablePadding>
                {categories.map((category) => (
                  <ListItem key={category.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            parseInt(category.id) === parseInt(checkedCat)
                              ? true
                              : false
                          }
                          onChange={() => handleSetCheckedCat(category.id)}
                        />
                      }
                      label={category.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExpand("brands")}>
              <ListItemText primary="Brands" />
              {expanded.brands ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={expanded.brands} timeout="auto" unmountOnExit>
            <div style={{ height: "250px", overflow: "auto" }}>
              <List component="div" disablePadding>
                {brands.map((brand) => (
                  <ListItem key={brand.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            parseInt(brand.id) === parseInt(checkedBrand)
                              ? true
                              : false
                          }
                          onChange={() => handleSetCheckedBrand(brand.id)}
                        />
                      }
                      label={brand.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Collapse>
        </List>
      </div>
    </div>
  );
};

export default Filters;
