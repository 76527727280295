import Header from './Header';
import Footer from './NewFooter';
import { useEffect } from 'react';

const BaseLayout = ({ history, children }: any) => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  });
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default BaseLayout;
