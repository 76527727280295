import {useState} from 'react';

import {
  Table,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material';
import {Close} from '@mui/icons-material';

export default function CompareTable({data, handleRemoveProduct}: any) {
  const [maxTextLength, setMaxTextLength] = useState<number>(70);
  const truncate: Function = (text: string) => {
    return text.length > maxTextLength ? text.substring(0, maxTextLength) + '...' : text;
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{border: '1px black solid'}}
        size='small'
        aria-label='a dense table'
      >
        <TableHead>
          <TableRow sx={{backgroundColor: '#D9D9D9', width: '100%'}}>
            <TableCell>
              <Typography variant='h6'>Product Details</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                variant='subtitle1'
                sx={{paddingLeft: '16px'}}
              >
                Product
              </Typography>
            </TableCell>
            {data?.map((product: any, index: any) => {
              return (
                <TableCell
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div className='border-2 inline-block md:mb-0 mb-3 rounded-xl overflow-hidden'>
                    <div className='mb-2 rounded-md flex items-center justify-center'>
                      <img
                        src={product.image}
                        alt={'product'}
                        width={206}
                        height={206}
                      />
                    </div>
                    <div className='p-2 w-[206px] flex items-center'>
                      <h1 className='font-medium text-primary-10 text-sm'>
                        {truncate(product.name)}
                      </h1>
                    </div>
                    <div className='flex items-center justify-center mb-2'>
                      <Button
                        color='error'
                        variant='outlined'
                        onClick={() => handleRemoveProduct(index)}
                      >
                        <Close /> Remove
                      </Button>
                    </div>
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant='subtitle1'
                sx={{paddingLeft: '16px'}}
              >
                Price
              </Typography>
            </TableCell>
            {data.map((product: any) => {
              return (
                <TableCell
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{paddingLeft: '16px'}}
                  >
                    {product.finalPrice}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant='subtitle1'
                sx={{paddingLeft: '16px'}}
              >
                Category
              </Typography>
            </TableCell>
            {data.map((product: any) => {
              return (
                <TableCell
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{paddingLeft: '16px'}}
                  >
                    {product.category}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant='subtitle1'
                sx={{paddingLeft: '16px'}}
              >
                Brand
              </Typography>
            </TableCell>
            {data.map((product: any) => {
              return (
                <TableCell
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{paddingLeft: '16px'}}
                  >
                    {product.brand}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
        <TableHead>
          <TableRow sx={{backgroundColor: '#D9D9D9', width: '100%'}}>
            <TableCell>
              <Typography variant='h6'>{data[0].category} Specification</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data[0].specification).map((row: any, index) => (
            <TableRow key={index}>
              <TableCell
                component='th'
                scope='row'
              >
                <Typography
                  variant='subtitle1'
                  sx={{paddingLeft: '16px'}}
                >
                  {row}
                </Typography>
              </TableCell>
              {data.map((product: any) => {
                return <TableCell>{product.specification[row]}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
