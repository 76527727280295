import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const ProductCard = ({product}: any) => {
    const navigate = useNavigate();
    const [maxTextLength, setMaxTextLength] = useState<number>(50);

    const truncate: Function = (text: string) => {
        return text.length > maxTextLength
            ? text.substring(0, maxTextLength) + '...'
            : text;
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 640) {
                // small viewport
                setMaxTextLength(30);
            } else if (window.innerWidth < 768) {
                // medium viewport
                setMaxTextLength(40);
            } else {
                // large viewport
                setMaxTextLength(70);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            className=' w-full h-full hover:border-[#0D083E] bg-white   hover:cursor-pointer flex flex-col   overflow-hidden'
            onClick={() => navigate(`/product/${product.id}`)}
        >
            <div className='mb-2 '>
                <img src={product.images[0].url[0]} alt={'product'}/>
            </div>
            <div className=' flex flex-grow gap-4 flex-col justify-between '>
                <h1 className='font-medium text-black text-sm'>
                    {truncate(product.name)}
                </h1>
            {/*    {product.discount ? (*/}
            {/*        <div className='flex mt-2'>*/}
            {/*            <div className='bg-info-20 p-1 rounded-sm text-xs w-fit text-white flex md:text-sm'>*/}
            {/*                {product.discount}%*/}
            {/*            </div>*/}
            {/*            <span className='text-primary-10 ml-2 text-xs md:text-sm'>*/}
            {/*  <s>Rp.{product.initial_price}</s>*/}
            {/*</span>*/}
            {/*        </div>*/}
            {/*    ) : (*/}
            {/*        ''*/}
            {/*    )}*/}
              <div className='flex flex-row bg-[#E43735]  h-8'>
                <div className='w-1/4 flex flex-col justify-center bg-[#0D083E] '>
                  <VisibilityIcon className="mx-auto" sx={{ color: "#ffffff",width: "20px" }} />
                </div>
                <div className='w-3/4 flex flex-col justify-center text-center h-full  font-medium  text-sm text-white'>
                 <p>Rp. {product.price}</p>
                </div>
              </div>

            </div>
        </div>
    );
};

export default ProductCard;
