import React from "react";

import B1 from "../assets/bannerbaru/banner2.webp";
import B2 from "../assets/bannerbaru/Steelseries Bundle Diablo IV_Mini Banner.webp";
import B3 from "../assets/bannerbaru/TX Stablizer Clip_Mini Banner.webp";
import B4 from "../assets/bannerbaru/banner3.webp";
import B5 from "../assets/bannerbaru/EC2-CW_Mini Banner.webp";
import B6 from "../assets/bannerbaru/Special Price O Pro & One Pro_Mini Banner.webp";
import B7 from "../assets/bannerbaru/Timeless82_Mini Banner.webp";


import { Typography } from "@mui/material";
import { banner1 } from "../assets/staticBanner";

const BarisBannera: React.FunctionComponent = () => {
  return (
    
    <div className="flex flex-col items-center justify-center w-4/5 ">
        
        <div className="flex flex-col md:flex-row w-full mt-2  ">
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 mb-2 px-2 justify-center">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar5.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/5';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar6.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/6';
                      }} />
          </div>
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 px-2 justify-center  ">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar7.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/7';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar12.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/12';
                      }} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full mt-2  ">
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 mb-2 px-2 justify-center">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar8.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/8';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar9.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/9';
                      }} />
          </div>
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 px-2 justify-center  ">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar10.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/10';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar11.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/11';
                      }} />
          </div>
        </div>
        

            
            
    </div>
    

  );
};

export default BarisBannera;
