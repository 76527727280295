import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Typography } from "@mui/material";

import ProductCard from "./ProductCard";

const DiscountedProductCard = () => {
  const navigate = useNavigate();

  const [newestProductList, setNewestProductList] = useState<any[]>([]);

  const handleGetNewestProducts = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/products/discount"
      );
      const data = result.data.data;
      setNewestProductList(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetNewestProducts();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-4/5 border-2 rounded-lg">
      <div className="flex items-center justify-between w-full px-4 m-4">
        <Typography
          sx={{
            fontWeight: { md: "400" },
            fontSize: { md: "2.5rem", sm: "2rem", xs: "1.5rem" },
          }}
        >
          Off Price
        </Typography>
        <Button onClick={() => navigate("/products")}>
          <Typography
            sx={{
              fontWeight: { md: "400" },
              fontSize: { md: "1rem", sm: "1rem", xs: "0.7rem" },
            }}
          >
            Lihat Semua Produk
          </Typography>
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-2 lg:grid-cols-6 lg:gap-2 w-full px-4 mb-4 overflow-x-auto">
        {newestProductList.slice(0, 12).map((product, index) => {
          return (
            <div key={index} className="md:mb-0 mb-3">
              <ProductCard product={product} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DiscountedProductCard;
