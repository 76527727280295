import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { SnackbarProvider } from "notistack";

import Home from "./routes/Home";
import Product from "./routes/Product";
import Products from "./routes/Products";
import Checkout from "./routes/Checkout";
import ShopingCart from "./routes/ShopingCart";
import AboutUsPage from "./routes/AboutNew";
import ContactUsPage from "./routes/ContactUsPage";
import Faq from "./routes/Faq";
import ReturnAndWarranty from "./routes/ReturnAndWarranty";
import BaseLayout from "./components/BaseLayout";
import CompareProduct from "./routes/CompareProduct";
import SuccessPage from "./routes/OrderSuccess";
import FailPage from "./routes/OrderFail";
import Orders from "./routes/Orders";
import CatPage from "./routes/CatPage";
import AboutNew from "./routes/AboutNew";
import ContactNew from "./routes/ContactNew";
import QnaBaru from "./routes/QnaBaru";
import NewFooter from "./components/NewFooter";
import Sliderbanner from "./components/ImageSlider";
import Warran from "./routes/Warran";

// import SliderBanner from "./components/ImageSlider";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<BaseLayout children={<Home />} />} />
      <Route
        path="/products"
        element={<BaseLayout children={<Products />} />}
        key={window.location.href}
      />
      <Route
        path="/products/:search"
        element={<BaseLayout children={<Products />} />}
        key={window.location.href}
      />
      <Route
        path="/product/:id"
        element={<BaseLayout children={<Product />} />}
        key={window.location.href}
      />
      <Route
        path="/cart"
        element={<BaseLayout children={<ShopingCart />} />}
        key={window.location.href}
      />
      <Route
        path="/compare"
        element={<BaseLayout children={<CompareProduct />} />}
        key={window.location.href}
      />
      <Route
        path="/about-us-2"
        element={<BaseLayout children={<AboutUsPage />} />}
        key={window.location.href}
      />
      <Route
        path="/contact-us"
        element={<BaseLayout children={<ContactNew />} />}
        key={window.location.href}
      />
      <Route
        path="/catpage"
        element={<BaseLayout children={<CatPage />} />}
        key={window.location.href}
      />
      <Route
        path="/faq1"
        element={<BaseLayout children={<Faq />} />}
        key={window.location.href}
      />
      <Route
        path="/return-and-warranty"
        element={<BaseLayout children={<Warran />} />}
        key={window.location.href}
      />
      <Route
        path="/checkout"
        element={<BaseLayout children={<Checkout />} />}
        key={window.location.href}
      />
      <Route
        path="/order/:id/success"
        element={<BaseLayout children={<SuccessPage />} />}
        key={window.location.href}
      />
      <Route
        path="/order/:id/expired"
        element={<BaseLayout children={<FailPage />} />}
        key={window.location.href}
      />
      <Route
        path="/orders"
        element={<BaseLayout children={<Orders />} />}
        key={window.location.href}
      />
      <Route
        path="/aboutnew"
        element={<BaseLayout children={<AboutNew />} />}
        key={window.location.href}
      />
      <Route
        path="/contactnew"
        element={<BaseLayout children={<ContactNew />} />}
        key={window.location.href}
      />
      <Route
        path="/faq"
        element={<BaseLayout children={<QnaBaru />} />}
        key={window.location.href}
      />
      <Route
        path="/newfooter"
        element={<BaseLayout children={<NewFooter />} />}
        key={window.location.href}
      />
      <Route
        path="/warran"
        element={<BaseLayout children={<Warran />} />}
        key={window.location.href}
      />
    </>
  )
);

const App: React.FunctionComponent = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      dense
    >
      <main>
        <RouterProvider router={router} />
      </main>
    </SnackbarProvider>
  );
};

export default App;
