import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "./ProductCard";

const ProductList = ({ products, hasMore, fetchData }: any) => {
  return (
    <div className="mb-10 mt-1 lg:my-10 px-2 md:px-0">
      <InfiniteScroll
        dataLength={products.length} //This is important field to render the next data
        next={fetchData}
        hasMore={hasMore}
        loader={
          <p style={{ textAlign: "center" }}>
            <b>Loading...</b>
          </p>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>There is no more product to show</b>
          </p>
        }
      >
        <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-3 lg:grid-cols-5 lg:gap-4">
          {products.map((product: any) => {
            return (
              <div key={product.id} className="w-full">
                <ProductCard product={product} />
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ProductList;
