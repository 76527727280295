import React from 'react';

const Spinner = ({ styles = '' }) => {
  return (
    <div className='flex items-center justify-center'>
      <div
        className={`${styles} border-b-2 border-[#DB1C24] rounded-full animate-spin`}
      ></div>
    </div>
  );
};

export default Spinner;
