import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

import Filters from '../components/Filters';
import ProductList from '../components/Products';
import DrawerComponent from '../components/Drawer';

const Products: React.FunctionComponent = () => {
  const [brand, setBrand] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [offset, setOffset] = useState<number>(50);
  const [products, setProducts] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true); // State untuk menandai apakah sedang dalam proses loading

  const location = useLocation();
  const { search } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const categoryIdFromUrl = searchParams.get('category');
  const brandIdFromUrl = searchParams.get('brand');

  useEffect(() => {
    // Set the initial value of category from the URL parameter
    if (categoryIdFromUrl) {
      setCategory(categoryIdFromUrl);
    }

    if (brandIdFromUrl) {
      setBrand(brandIdFromUrl);
    }

    // Tambahkan penundaan selama 2 detik sebelum memuat data produk
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Hapus timer saat komponen dibongkar
    return () => clearTimeout(timer);
  }, [categoryIdFromUrl, brandIdFromUrl]);

  const handleGetProducts = async () => {
    try {
      const timestamp = Date.now();
      const response = await axios.get(
        `https://goodgamingshop.com/api/v1/products?search=${search || ''}&brand=${
          brand || ''
        }&category=${category || ''}&limit=50&offset=0&_=${timestamp}`,
      );
      const data = response.data.data;
      setProducts(data);
      if (data.length === 0 || data.length < 20) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetMoreProducts = async () => {
    try {
      const timestamp = Date.now();
      const response = await axios.get(
        `https://goodgamingshop.com/api/v1/products?search=${search ? search : ''}&brand=${
          brand || ''
        }&category=${category || ''}&limit=50&offset=${offset}&_=${timestamp}`,
      );
      const data = response.data.data;
      setProducts([...products, ...data]);
      if (data.length === 0 || data.length < 20) {
        setHasMore(false);
      }
      setOffset(offset + 50);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!loading) {
      handleGetProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, search, category, brand]);

  // Menampilkan pesan loading jika masih dalam proses loading
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className='flex-row gap-8 items-start mx-10 lg:flex'>
        <DrawerComponent anchor='left' setCategory={setCategory} setBrand={setBrand} />
        <Filters setCategory={setCategory} setBrand={setBrand} />
        <ProductList products={products} hasMore={hasMore} fetchData={handleGetMoreProducts} />
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default Products;
