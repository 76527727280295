import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';

import {TextField, Typography} from '@mui/material';

import AsyncSelect from '../components/AsyncSelect';

type DeliveryPayloadType = {
  name: string;
  email: string;
  phoneNumber: number | string;
  province: number | string;
  city: number | string;
  kecamatan: string;
  kelurahan: string;
  address: string;
  postalCode: number | string;
  note: string;
};

const initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  province: '',
  city: '',
  kecamatan: '',
  kelurahan: '',
  address: '',
  postalCode: '',
  note: '',
};

const Checkout: React.FC = () => {
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [province, setProvince] = useState<any>();
  const [cityList, setCityList] = useState<any[]>([]);
  const [city, setCity] = useState<any>();
  const [deliveryPaylaod, setDeliveryPayload] = useState<DeliveryPayloadType>(initialState);
  const [selectedDelivery, setSelectedDelivery] = useState<{
    id: number;
    name: string;
  }>();
  const [deliveryServiceList, setDeliveryServiceList] = useState<any[]>([]);
  const [deliveryService, setDeliveryService] = useState<any>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [trueTotalPrice, setTrueTotalPrice] = useState(0);

  const CartFromLocalStorage = JSON.parse(localStorage.getItem('cart') || '[]');
  const [cart, setCart] = useState<any>(CartFromLocalStorage);
  const navigate = useNavigate();

  const handleGetCartItems = () => {
    let updatedResults = [];
    for (let i = 0; i < cart.length; i++) {
      let updatedResult = {
        name: cart[i].name,
        quantity: cart[i].quantity,
        image: cart[i].image,
        finalPrice: cart[i].finalPrice,
        variant: cart[i].variant,
      };
      updatedResults.push(updatedResult);
    }
    return updatedResults;
  };

  const handleGetTotalPrice = async () => {
    try {
      const result = await axios.post(`https://goodgamingshop.com/api/v1/order/totalPrice`, {
        cart: cart,
      });
      const data = result?.data;
      setTotalPrice(data?.totalPrice);
    } catch (err) {
      console.log(err);
    }
  };

  const getFinalPrice = () => {
    if (selectedDelivery?.name === 'Ambil di Tempat') {
      return totalPrice;
    } else {
      return totalPrice + (deliveryService?.cost || 0);
    }
  };

  const delivery = [
    {id: 1, name: 'Ambil di Tempat'},
    {id: 2, name: 'Dikirim'},
  ];

  const handleSetProvince = (value: any) => {
    setProvince(value);
    setDeliveryPayload({
      ...deliveryPaylaod,
      province: value,
    });
  };

  const handleSetCity = (value: any) => {
    setCity(value);
    setDeliveryPayload({
      ...deliveryPaylaod,
      city: value?.name,
    });
  };

  const handleSetDelivery = (value: any) => {
    setSelectedDelivery(value);
    if (value?.name === 'Ambil di Tempat') {
      setDeliveryService({id: 0, name: 'Ambil di Tempat', cost: 0});
    }
  };

  const handleSetDeliveryServiceList = (results: any) => {
    let updatedResults = [];
    let index = 1;
    for (let i = 0; i < results.length; i++) {
      let updatedResult = {
        id: index,
        name: results[i].service,
        cost: results[i].cost,
      };
      updatedResults.push(updatedResult);
      index += 1;
    }
    return updatedResults;
  };

  const handleGetProvinceList = async () => {
    try {
      const result = await axios.get('https://goodgamingshop.com/api/v1/province');
      const data = result?.data;
      setProvinceList(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetCityList = async () => {
    try {
      const result = await axios.get(`https://goodgamingshop.com/api/v1/city/${province?.id}`);
      const data = result?.data;
      setCityList(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetDeliveryList = async () => {
    try {
      if (selectedDelivery?.name === 'Dikirim' && city) {
        const result = await axios.post(
          `https://goodgamingshop.com/api/v1/cost?destination=${city?.id}`,
          {
            cart: cart,
          },
        );
        const data = result?.data;
        setDeliveryServiceList(handleSetDeliveryServiceList(data?.data));
      } else {
        setDeliveryServiceList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePostCheckout = async () => {
    try {
      const selectedCourier =
        selectedDelivery?.name === 'Ambil di Tempat' ? 'Ambil di Tempat' : deliveryService?.name;
      const selectedShippingCost =
        selectedDelivery?.name === 'Ambil di Tempat' ? 0 : deliveryService?.cost;

      const result = await axios.post('https://goodgamingshop.com/api/v1/order', {
        name: deliveryPaylaod.name,
        handphone: deliveryPaylaod.phoneNumber,
        email: deliveryPaylaod.email,
        address: deliveryPaylaod.address,
        kelurahan: deliveryPaylaod.kelurahan,
        kecamatan: deliveryPaylaod.kecamatan,
        kota: deliveryPaylaod.city,
        zipcode: deliveryPaylaod.postalCode,
        price: totalPrice,
        ongkir: selectedShippingCost,
        TotalPrice: totalPrice + selectedShippingCost,
        note: deliveryPaylaod.note,
        kurir: selectedCourier,
        item: handleGetCartItems(),
      });
      const data = result?.data;
      setTimeout(() => {
        window.open(data?.json.Data.Url, '_self');
      }, 1000);

      localStorage.setItem('cart', JSON.stringify([]));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetProvinceList();
    handleGetTotalPrice();
  }, []);

  useEffect(() => {
    if (province) {
      handleGetCityList();
    }
  }, [province]);

  useEffect(() => {
    if (city) {
      handleGetDeliveryList();
    }
  }, [city, selectedDelivery]);

  return (
    <div className='mb-10  w-full lg:w-full'>
      <div className='h-full bg-gray-100 pt-10 items-center'>
        <h1 className='mb-10 ml-4 md:ml-28 text-2xl font-bold'>Checkout</h1>
        <div className='md:mx-28 md:px-6 lg:flex lg:space-x-6 bg-white p-4 rounded-lg'>
          <div className='rounded-lg lg:w-2/3'>
            <div className='flex flex-col items-start gap-8 justify-start w-full'>
              <Typography variant='h6'>Identitas Pemesan</Typography>
              <TextField
                variant='outlined'
                label='Nama Lengkap'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    name: e.target.value,
                  })
                }
                color='primary'
                value={deliveryPaylaod.name}
              />
              <TextField
                variant='outlined'
                label='Email'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    email: e.target.value,
                  })
                }
                value={deliveryPaylaod.email}
              />
              <TextField
                variant='outlined'
                label='Nomor Telepon'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    phoneNumber: e.target.value,
                  })
                }
                value={deliveryPaylaod.phoneNumber}
              />
              <hr className='my-4 w-full' />
              <Typography variant='h6'>Detail Pengiriman</Typography>
              <AsyncSelect
                label='Provinsi'
                option={provinceList}
                setSelected={handleSetProvince}
                defaultValue={provinceList}
              />
              <AsyncSelect
                label='Kota / Kabupaten'
                option={cityList}
                setSelected={handleSetCity}
                defaultValue={cityList}
              />
              <TextField
                variant='outlined'
                label='Kecamatan'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    kecamatan: e.target.value,
                  })
                }
                value={deliveryPaylaod.kecamatan}
              />
              <TextField
                variant='outlined'
                label='Kelurahan'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    kelurahan: e.target.value,
                  })
                }
                value={deliveryPaylaod.kelurahan}
              />
              <TextField
                variant='outlined'
                label='Alamat Lengkap'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    address: e.target.value,
                  })
                }
                multiline
                minRows={4}
                maxRows={4}
                value={deliveryPaylaod.address}
              />
              <TextField
                variant='outlined'
                label='Kode Pos'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    postalCode: e.target.value,
                  })
                }
                value={deliveryPaylaod.postalCode}
              />
              <AsyncSelect
                label='Pilih Pengiriman'
                option={delivery}
                setSelected={handleSetDelivery}
                defaultValue={delivery}
              />
              {selectedDelivery?.name === 'Dikirim' && (
                <AsyncSelect
                  label='Pilih Tipe Pengiriman'
                  option={deliveryServiceList}
                  setSelected={setDeliveryService}
                  defaultValue={deliveryServiceList}
                />
              )}
              <TextField
                variant='outlined'
                label='Tambahkan Catatan Pengiriman'
                fullWidth
                onChange={(e) =>
                  setDeliveryPayload({
                    ...deliveryPaylaod,
                    note: e.target.value,
                  })
                }
                multiline
                minRows={4}
                maxRows={4}
              />
            </div>
          </div>
          <div className='mt-6 h-full rounded-lg border mx-1 lg:mx-0 bg-white p-6 lg:mt-0 lg:w-1/3'>
            <div className='flex justify-between'>
              <p className='text-gray-700 font-medium text-xl'>Ringkasan Belanja</p>
            </div>
            <hr className='my-4' />
            <div className='mb-2 flex justify-between'>
              <p className='text-gray-700'>Total harga</p>
              <p className='text-gray-700'>
                Rp {totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
            {selectedDelivery?.name === 'Dikirim' && (
              <div className='flex justify-between'>
                <p className='text-gray-700'>Biaya Kirim</p>
                <p className='text-gray-700'>
                  Rp {deliveryService?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>
            )}
            <hr className='my-4' />
            <div className='flex justify-between'>
              <p className='text-lg font-bold'>Total Tagihan</p>
              <div className=''>
                <p className='mb-1 text-lg font-bold'>
                  Rp{' '}
                  {getFinalPrice()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                </p>
              </div>
            </div>
            <button
              className='mt-6 w-full rounded-md bg-info-20 py-1.5 font-medium text-blue-50 hover:bg-blue-600'
              onClick={() => handlePostCheckout()}
            >
              Check out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
