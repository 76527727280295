import React from 'react';

import Loca from "../assets/logoqna/loca.webp";
import box from "../assets/logoqna/box.webp";
import jam from "../assets/logoqna/jam.webp";
import kirim from "../assets/logoqna/kirim.webp";
import www from "../assets/logoqna/www.webp";


import {Typography} from '@mui/material';

const QnaBaru: React.FunctionComponent = () => {
  return (
    <div>
    <div className='flex flex-col p-20 gap-10 w-full bg-white'>
      <div>
        <div className="w-full mb-8">
                    <h1 style={{ color: '#1A1460' , textAlign: 'left',fontWeight: 'bold' }} className="text-xl md:text-2xl lg:text-3xl font-roboto self-center">
                    Frequently Asked Question (FAQ)
                    </h1>
        </div>
        <div className="w-full my-3">
                    <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                    Walkthrough from quick answers to questions you have.
                    </h1>          
        </div>        
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col w-full md:w-1/2">
        <div className="flex flex-row">
          <div className="flex-none pt-8 px-4 w-1/8">
            <img src={Loca} alt="" className="" />
            </div>
            <div className="w-7/8  pr-8">
              <h1 style={{ color: '#000000', textAlign: 'left',fontWeight: 'bold' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Dimana sih lokasi Offline Store GOODGAMINGSHOP ?
              </h1>
              <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Toko offline GOODGAMINGSHOP ada di dua tempat, yaitu di Ruko Mangga Dua Mall no.45 A, dan di Ruko Pisa Grande Paramount F/7 Gading Serpong.
              </h1>     
            </div>
          </div>
          <div className="flex flex-row">
          <div className="flex-none pt-8 px-4 w-1/8">
              <img src={www} alt=""  />
            </div>
            <div className="w-7/8 pr-8">
              <h1 style={{ color: '#000000', textAlign: 'left',fontWeight: 'bold' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Apakah bisa beli lewat online juga?
              </h1>
              <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Tentu saja bisa. Kamu bisa beli gaming gear yang kamu butuhkan di GGS via Website, Marketplace (Tokopedia, Shopee, dll), dan atau via chat lewat DM Whatsapp ke nomer 0812 – 63 – 665 – 665, atau melalui DM Instagram.
              </h1>     
            </div>
          </div>
          <div className="flex flex-row">
          <div className="flex-none pt-8 px-4 w-1/8">
              <img src={box} alt=""  />
            </div>
            <div className="w-7/8 pr-8">
              <h1 style={{ color: '#000000', textAlign: 'left',fontWeight: 'bold' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Apakah stock yang ada di marketplace sama dengan stock yang ada di toko?
              </h1>
              <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Stock yang ada di toko sama persis dengan yang ada di marketplace, hanya saja terkadang ada keterlambatan pengiriman sehingga membuat stocknya menjadi berbeda. Untuk lebih amannya, kamu bisa chat ke admin marketplace-nya sebelum meng-order ya.
              </h1>     
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <div className="flex flex-row">
          <div className="flex-none pt-8 px-4 w-1/8">
              <img src={jam} alt=""  />
            </div>
            <div className="w-7/8 pr-8">
              <h1 style={{ color: '#000000', textAlign: 'left',fontWeight: 'bold' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Kapan saja jam operasional GOODGAMINGSHOP? Baik Offline Store dan Online Store?
              </h1>
              <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              - GOODGAMINGSHOP EXPERIENCE ZONE - Ruko Mangga Dua Mall no. 45 A : 10.00 – 18.00 WIB
- GOODGAMINGSHOP x KEEB CORNER – Ruko Pisa Grande Blok F/7 : 12.00 – 20.00 WIB
Adapun jam operasional dari admin online kami mengikuti jam operasional dari GOODGAMINGSHOP EXPERIENCE ZONE ya.
              </h1>     
            </div>
          </div>
          <div className="flex flex-row">
          <div className="flex-none pt-8 px-4 w-1/8">
              <img src={kirim} alt=""  />
            </div>
            <div className="w-7/8 pr-8">
              <h1 style={{ color: '#000000', textAlign: 'left',fontWeight: 'bold' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Apakah bisa melakukan pengiriman Instan dan Same Day?
              </h1>
              <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
              Pengiriman Same Day hanya bisa dilakukan jika kamu tinggal di area Jakarta dan sekitarnya, pada pukul 10.00 – 17.00 WIB.
              </h1>     
            </div>
          </div>
        </div>


      </div>
      
    </div>
    </div>
  );
};

export default QnaBaru;
