import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

const CategoryCard = () => {
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleGetCategoryList = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/categories"
      );
      const data = result.data.data;
      setCategoryList(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetCategoryList();
  }, []);

  return (
    <div className="flex flex-col items-center mt-4 justify-center w-4/5 border-2 rounded-lg">
      <div className="flex items-center w-full px-4 m-4">
        <Typography align="center" variant="h4" sx={{ fontWeight: "400" }}>
          Category
        </Typography>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-7 px-4 md:gap-2 mb-4 gap-1">
        {categoryList.map((category) => {
          return (
            <div className="px-4 flex md:mb-0 mb-3 hover:border-gray-300 hover:cursor-pointer items-center justify-center rounded-md">
              <div
                className="block"
                onClick={() => navigate(`/products?category=${category.id}`)}
              >
                <img src={category?.image} alt={"brand"} />
                <p className="font-normal text-xs text-slate-500 text-center mt-1">
                  {category.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryCard;
