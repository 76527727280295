import React, {useState} from 'react';
import axios from 'axios';

import {TextField, Typography} from '@mui/material';

type OrderItemsType = {
  id: number;
  status: string;
  item: any[];
  price: number;
  ongkir: number;
  TotalPrice: number;
  resi: string;
};

const initialState: OrderItemsType = {
  id: 0,
  status: '',
  item: [],
  price: 0,
  ongkir: 0,
  TotalPrice: 0,
  resi: '',
};

const Orders: React.FC = () => {
  const [orderCode, setorderCode] = useState<string>('');
  const [orderItems, setOrderItems] = useState<OrderItemsType>(initialState);

  const handleGetOrderItems = async () => {
    try {
      const result = await axios.get(`https://goodgamingshop.com/api/v1/order/status/${orderCode}`);
      const data = result?.data;
      setOrderItems(data?.order);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='mb-10 w-full lg:w-full'>
      <div className='h-full bg-gray-100 pt-10 items-center'>
        <h1 className='mb-10 md:ml-36 text-2xl font-bold justify-center flex md:justify-start'>
          Check Order
        </h1>
        <div className='mx-auto lg:mx-28 lg:px-6 flex flex-col gap-8 lg:space-x-6 xl:px-0'>
          <Typography
            variant='subtitle1'
            sx={{marginLeft: '24px'}}
          >
            Enter Your Order Code to Check Order Status
          </Typography>
          <div className='flex flex-col md:flex-row justify-center items-center gap-4 w-full md:justify-start ml-0  '>
            <TextField
              variant='outlined'
              label='Order code'
              fullWidth
              onChange={(e) => setorderCode(e.target.value)}
              value={orderCode}
            />
            <button
              className='w-56 h-auto rounded-md bg-info-20 py-1.5 font-medium text-blue-50 hover:bg-blue-600'
              onClick={handleGetOrderItems}
            >
              Check
            </button>
          </div>
          {orderItems.status !== '' && (
            <>
              <div className='flex flex-col gap-2 mx-4'>
                <Typography variant='h6'>Order Status</Typography>
                <div className='flex flex-col ml-4'>
                  <Typography>Status: {orderItems.status}</Typography>
                  {orderItems.resi !== '' && <Typography>No. Resi: {orderItems.resi}</Typography>}
                </div>
              </div>
              <div className='flex flex-col gap-4 mx-4'>
                <Typography variant='h6'>Order Details</Typography>
                <div className='flex flex-col md:flex-row justify-between'>
                  <div>
                    {orderItems.item.map((e, index) => {
                      return (
                        <div
                          key={index}
                          className='flex'
                        >
                          <img
                            src={e.image}
                            alt='product'
                            className='w-[69px] h-[69px] rounded-lg lg:w-40 lg:h-full'
                          />
                          <div className='mt-0 ml-2 lg:mt-5'>
                            <div className='hover: cursor-pointer'>
                              <p
                                className='text-sm font-medium text-gray-700 lg:text-base'
                                // onClick={() => navigate(`/product/${item.id}`)}
                              >
                                {e.name}
                              </p>
                            </div>
                            <p className='text-xs font-medium text-gray-700'>{e.variant}</p>
                            <div className='flex mt-3'>
                              <span className='text-primary-10 ml-2'>
                                {e.quantity} x Rp{' '}
                                {e.finalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className='mt-6 h-full rounded-lg border mx-1 lg:mx-0 bg-white p-6 lg:mt-0 lg:w-1/3'>
                    <div className='flex justify-between'>
                      <p className='text-gray-700 font-medium text-xl'>Ringkasan Belanja</p>
                    </div>
                    <hr className='my-4' />
                    <div className='mb-2 flex justify-between'>
                      <p className='text-gray-700'>Total harga</p>
                      <p className='text-gray-700'>
                        Rp {orderItems.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      </p>
                    </div>
                    <div className='flex justify-between'>
                      <p className='text-gray-700'>Biaya Kirim</p>
                      <p className='text-gray-700'>
                        Rp {orderItems.ongkir.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      </p>
                    </div>
                    <hr className='my-4' />
                    <div className='flex justify-between'>
                      <p className='text-lg font-bold'>Total Tagihan</p>
                      <div className=''>
                        <p className='mb-1 text-lg font-bold'>
                          Rp{' '}
                          {orderItems.TotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
