import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import HomeIcon from "@mui/icons-material/Home";
import logo from "../assets/whitelogo.png";

import { ShoppingCart } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

const Header: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const handleSetKeyword = (value: string) => {
    setKeyword(value);
  };
  const handleEnterSearch = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/products/${keyword}`);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);
  const open4 = Boolean(anchorEl4);
  const handleClick4 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl4(event.currentTarget);
  };

  const [anchorEl5, setAnchorEl5] = React.useState<null | HTMLElement>(null);
  const open5 = Boolean(anchorEl5);
  const handleClick5 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl5(event.currentTarget);
  };

  const [anchorEl6, setAnchorEl6] = React.useState<null | HTMLElement>(null);
  const open6 = Boolean(anchorEl6);
  const handleClick6 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl6(event.currentTarget);
  };

  const [anchorEl7, setAnchorEl7] = React.useState<null | HTMLElement>(null);
  const open7 = Boolean(anchorEl7);
  const handleClick7 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl7(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const handleClose6 = () => {
    setAnchorEl6(null);
  };

  const handleClose7 = () => {
    setAnchorEl7(null);
  };
  return (
    <nav
      id="header"
      className="w-full z-30 top-0 sticky lg:static lg:top-10 pt-1 bg-[#0D083E] shadow-sm lg:shadow-lg border-b"
    >
      <div className="row lg:border-b-2">
        <div className="w-full flex items-center mt-0 px-2 py-2">
          <div className="flex items-center w-full md:w-full justify-between lg:justify-between lg:mx-40">
            <div className="flex">
              <a
                className="inline-block no-underline hover:text-black font-medium text-lg py-2 px-4 lg:-ml-2"
                href="/"
              >
                {" "}
                <img src={logo} alt="" width="150px" />
              </a>
            </div>
            <div className="flex">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: {
                    xs: "auto",
                    lg: "500px",
                  },
                }}
                style={{
                  border: "1px solid gray",
                }}
              >
                <IconButton
                  sx={{ p: "10px" }}
                  aria-label="menu"
                  onClick={(e) => handleEnterSearch(e)}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Cari produk"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={keyword}
                  onChange={(e) => handleSetKeyword(e.target.value)}
                  onKeyDown={(e) => handleEnterSearch(e)}
                  style={{ width: "100%" }}
                />
              </Paper>
            </div>

            <div className="flex gap-2">
              <div className="hidden lg:inline">
                <IconButton
                  size="small"
                  sx={{ padding: "4px", borderRadius: "8px", gap: "2px" }}
                  onClick={() => navigate("/orders")}
                >
                  <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
                    <DeliveryDiningIcon sx={{ color: "#ffffff" }} />{" "}
                    <div className="hidden lg:inline text-white">
                      <Typography
                        sx={{

                          "@media (min-width:600px)": { fontSize: "14px" },
                        }}
                      >
                        Check Order
                      </Typography>
                    </div>
                  </div>
                </IconButton>
              </div>
              <div className="hidden lg:inline">
                <IconButton
                  size="small"
                  sx={{ padding: "4px", borderRadius: "8px", gap: "2px" }}
                  onClick={() => navigate("/compare")}
                >
                  <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
                    <CompareArrowsIcon className="" sx={{ color: "#ffffff" }} />
                    <div className="hidden lg:inline text-white">
                      <Typography
                        sx={{

                          "@media (min-width:600px)": { fontSize: "14px", color: "FFFFFFFF" },
                        }}
                      >
                        Compare
                      </Typography>
                    </div>
                  </div>
                </IconButton>
              </div>
              <div className="hidden lg:inline text-white">
                <IconButton
                  size="small"
                  sx={{ padding: "4px", borderRadius: "8px", gap: "2px" }}
                  onClick={() => navigate("/cart")}
                >
                  <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
                    <ShoppingCart sx={{ color: "#ffffff" }} />
                    <div className="hidden lg:inline  text-white">
                      <Typography
                        sx={{

                          "@media (min-width:600px)": { fontSize: "14px" },
                        }}
                      >
                        Cart
                      </Typography>
                    </div>
                  </div>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex lg:hidden justify-center mx-28">
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#ffffff",
            },
            color: "#ffffff",
            fontWeight: "bold",
            "@media (min-width:100px)": { fontSize: "12px" },
            "@media (min-width:511px)": {
              fontSize: "12px",
            },
          }}
          onClick={() =>
            (window.location.href = "https://goodgamingshop.com/catpage")
          }
        >
          Shop By Category
        </Button>

        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#ab161b",
            fontWeight: "bold",
            "@media (min-width:100px)": { fontSize: "12px" },
            "@media (min-width:511px)": {
              fontSize: "12px",
            },
          }}
          onClick={() =>
            (window.location.href = "https://aftersales.goodgamingshop.com/")
          }
        >
          AfterSales
        </Button>
      </div>
      <div className="w-full row lg:flex hidden justify-between px-28 bg-white">
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Mouse & Acc
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=1")
            }
          >
            Gaming Mouse
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=20"
          >
            Mouse Bungee
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=21"
          >
            Anti-slip Tape
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          onClick={() =>
            (window.location.href =
              "https://goodgamingshop.com/products?category=8")
          }
        >
          Mousepad
        </Button>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-2"
          aria-controls={open2 ? "basic-menu-2" : undefined}
          aria-haspopup="true"
          aria-expanded={open2 ? "true" : undefined}
          onClick={handleClick2}
        >
          Keyboard & Acc
        </Button>
        <Menu
          id="basic-menu-2"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button-2",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=2")
            }
          >
            Keyboard
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=11")
            }
          >
            Keycaps
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=19")
            }
          >
            Wrist Rest
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=23")
            }
          >
            Aksesoris
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-3"
          aria-controls={open3 ? "basic-menu-3" : undefined}
          aria-haspopup="true"
          aria-expanded={open3 ? "true" : undefined}
          onClick={handleClick3}
        >
          Audio Device
        </Button>
        <Menu
          id="basic-menu-3"
          anchorEl={anchorEl3}
          open={open3}
          onClose={handleClose3}
          MenuListProps={{
            "aria-labelledby": "basic-button-3",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=4")
            }
          >
            Gaming Headset
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=12")
            }
          >
            Gaming Earphone
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=13")
            }
          >
            Microphone
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=24")
            }
          >
            Gaming Speaker
          </MenuItem>
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=16")
            }
          >
            Headset Stand
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-4"
          aria-controls={open4 ? "basic-menu-4" : undefined}
          aria-haspopup="true"
          aria-expanded={open4 ? "true" : undefined}
          onClick={handleClick4}
        >
          Streaming Device
        </Button>
        <Menu
          id="basic-menu-4"
          anchorEl={anchorEl4}
          open={open4}
          onClose={handleClose4}
          MenuListProps={{
            "aria-labelledby": "basic-button-4",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=15")
            }
          >
            Capture Card
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=18"
          >
            Camera
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-5"
          aria-controls={open5 ? "basic-menu-5" : undefined}
          aria-haspopup="true"
          aria-expanded={open5 ? "true" : undefined}
          onClick={handleClick5}
        >
          Gamepad & Controller
        </Button>
        <Menu
          id="basic-menu-5"
          anchorEl={anchorEl5}
          open={open5}
          onClose={handleClose5}
          MenuListProps={{
            "aria-labelledby": "basic-button-5",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=14")
            }
          >
            Gamepad
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=25"
          >
            Flight Simulator
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=26"
          >
            Steering Wheel
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-6"
          aria-controls={open6 ? "basic-menu-6" : undefined}
          aria-haspopup="true"
          aria-expanded={open6 ? "true" : undefined}
          onClick={handleClick6}
        >
          Chair & Desk
        </Button>
        <Menu
          id="basic-menu-6"
          anchorEl={anchorEl6}
          open={open6}
          onClose={handleClose6}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=17")
            }
          >
            Gaming Chair
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=27"
          >
            Gaming Desk
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#000000",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          id="basic-button-7"
          aria-controls={open7 ? "basic-menu-7" : undefined}
          aria-haspopup="true"
          aria-expanded={open7 ? "true" : undefined}
          onClick={handleClick7}
        >
          Accessories
        </Button>
        <Menu
          id="basic-menu-7"
          anchorEl={anchorEl7}
          open={open7}
          onClose={handleClose7}
          MenuListProps={{
            "aria-labelledby": "basic-button-7",
          }}
        >
          <MenuItem
            component="a"
            onClick={() =>
              (window.location.href =
                "https://goodgamingshop.com/products?category=22")
            }
          >
            Gaming Glasses
          </MenuItem>
          <MenuItem
            component="a"
            href="https://goodgamingshop.com/products?category=23"
          >
            Accessories
          </MenuItem>
        </Menu>
        <Button
          sx={{
            padding: "10px",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#444444",
            },
            color: "#ab161b",
            fontWeight: "bold",
            "@media (min-width:600px)": { fontSize: "8px" },
            "@media (min-width:1170px)": { fontSize: "10px" },
            "@media (min-width:1323px)": { fontSize: "12px" },
            "@media (min-width:1476px)": {
              fontSize: "14px",
            },
          }}
          onClick={() =>
            (window.location.href = "https://aftersales.goodgamingshop.com/")
          }
        >
          AfterSales
        </Button>
      </div>
      <div className="lg:hidden">
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon sx={{ color: "#848484" }} />}
            onClick={() => navigate("/")}
          />
          <BottomNavigationAction
            label="Order"
            icon={<DeliveryDiningIcon sx={{ color: "#848484" }} />}
            onClick={() => navigate("/orders")}
          />
          <BottomNavigationAction
            label="Compare"
            icon={<CompareArrowsIcon sx={{ color: "#848484" }} />}
            onClick={() => navigate("/compare")}
          />
          <BottomNavigationAction
            label="Cart"
            icon={<ShoppingCart sx={{ color: "#848484" }} />}
            onClick={() => navigate("/cart")}
          />
        </BottomNavigation>
      </div>
    </nav>
  );
};

export default Header;
