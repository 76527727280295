import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Typography} from '@mui/material';

const FailPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className='bg-fail h-screen bg-no-repeat bg-cover'>
      <div className='bg-black bg-opacity-60 h-screen w-screen flex justify-center'>
        <div className='flex flex-col items-center justify-center gap-16'>
          <Typography
            sx={{color: 'white'}}
            variant='h2'
          >
            Sorry, we can’t process your order.
          </Typography>
          <div className='flex flex-col gap-4 items-center justify-center'>
            <Typography
              sx={{color: 'white'}}
              variant='h5'
            >
              Your payment has expired. Sorry for the inconvenience.
            </Typography>
          </div>
          <div className='flex gap-8 w-full items-center justify-center'>
            <button
              className='mt-6 w-1/3 h-full rounded-md bg-info-20 py-1.5 font-medium text-blue-50 hover:bg-blue-600'
              onClick={() => navigate('/')}
            >
              Back to Shopping
            </button>
            <button
              className='mt-6 w-1/3 h-full rounded-md border-white border-2 py-1.5 font-medium text-blue-50 hover:border-blue-600 hover:text-blue-600'
              onClick={() => navigate('/cart')}
            >
              Check Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailPage;
