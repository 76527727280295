import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button, Typography } from "@mui/material";

import ProductCard from "./ProductCard";

const NewestProductCard = () => {
  const navigate = useNavigate();

  const [newestProductList, setNewestProductList] = useState<any[]>([]);

  const handleGetNewestProducts = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/products/latest"
      );
      const data = result.data.data;
      setNewestProductList(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetNewestProducts();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center pb-8 w-full px-4  bg-[url('/src/assets/bghero.png')]">
      <div className=" bg-[#E43735] text-white text-center border border-[#0D083E] rounded-md p-1 mb-8 -mt-4">
          <div className=" bg-[#E43735] text-white text-center border border-[#0D083E] rounded-md px-4 py-2">
<h1 className='font-bold text-lg md:text-2xl'>
    ONLY ON WEBSITE
</h1>
          </div>
      </div>
      <div className="grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-4  lg:grid-cols-6  w-full md:w-4/5 px-4  mb-8 overflow-x-auto">
        {newestProductList.slice(0, 12).map((product, index) => {
          return (
            <div key={index} className="md:mb-0 mb-3">
              <ProductCard product={product} />
            </div>
          );
        })}
      </div>
        <Typography align="center" className="w-3/4 md:w-1/3 mx-auto text-center cursor-pointer bg-[#E43735] rounded-md px-8 py-2 text-white text-sm md:text-lg"
                    onClick={() => {
                        window.location.href = 'https://goodgamingshop.com/product';
                    }}
            // sx={{
            //     fontWeight: { md: '400' },
            //     fontSize: { md: '2.5rem', sm: '2rem', xs: '1.5rem' },
            // }}
        >
            Special On Website
        </Typography>
    </div>
  );
};

export default NewestProductCard;
