import React from "react";

import Build from "../assets/build.jpg";
import Pc from "../assets/pc.jpg";
import Consign from "../assets/consign.jpg";

import { Typography } from "@mui/material";

const Topc: React.FunctionComponent = () => {
  return (
    <div className="items-center lg:w-4/5 flex flex-col ">
        <Typography align="center" className="cursor-pointer" 
            onClick={() => {
                window.location.href = 'https://goodgamingshop.com/catpage';
            }}
            sx={{
            fontWeight: { md: '400' },
            fontSize: { md: '2.5rem', sm: '2rem', xs: '1.5rem' },
          }}
          >
        Top Category
        </Typography>

    
        <div className="mb-12 flex flex-col gap-y-8 lg:flex-row items-center justify-between  rounded-lg lg:mx-4">

            <div className="flex flex-row items-center justify-between rounded-lg">

                <div className="w-full flex-col items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/ggs-dewa-ruci/699b1146-598b-4f20-929d-1a35f2e82f5c.webp"
                        alt="Gambar 1"
                        className="w-full px-3 cursor-pointer"
                        onClick={() => {
                            window.location.href = 'https://goodgamingshop.com/products?category=2';
                        }}
                    />
                    <Typography align="center" fontWeight="bold" className="md:text-3.5xl text-1.5xl">Gaming
                        Keyboard</Typography>
                </div>
                <div className="w-full flex-col items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/ggs-dewa-ruci/e352a9fc-95bb-4b01-b98a-787a99a004c4.webp"
                        alt="Gambar 1"
                        className="w-full px-3 cursor-pointer"
                        onClick={() => {
                            window.location.href = 'https://goodgamingshop.com/products?category=17';
                        }}
                    />
                    <Typography align="center" fontWeight="bold" className="md:text-3.5xl text-1.5xl">Gaming
                        Chair</Typography>
                </div>
                <div className="w-full flex-col items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/ggs-dewa-ruci/9615fda6-cbfc-4dd8-8e7a-e552e459fee5.webp"
                        alt="Gambar 1"
                        className="w-full px-3 cursor-pointer"
                        onClick={() => {
                            window.location.href = 'https://goodgamingshop.com/products?category=4';
                        }}
                    />
                    <Typography align="center" fontWeight="bold" className="md:text-3.5xl text-1.5xl">Gaming
                        Headset</Typography>
                </div>
                <div className="w-full flex-col items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/ggs-dewa-ruci/e9a9f020-ccea-4142-9946-3979b3cc61de.webp"
                        alt="Gambar 1"
                        className="w-full px-3 cursor-pointer"
                        onClick={() => {
                            window.location.href = 'https://goodgamingshop.com/products?category=14';
                        }}
                    />
                    <Typography align="center" fontWeight="bold" className="md:text-3.5xl text-1.5xl">Gaming
                        Gamepad</Typography>
                </div>
                <div className="w-full flex-col items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/ggs-dewa-ruci/5a354da9-fa64-4a1d-a470-5b2e1076d03b.webp"
                        alt="Gambar 1"
                        className="w-full px-3 cursor-pointer"
                        onClick={() => {
                            window.location.href = 'https://goodgamingshop.com/products?category=1';
                        }}
                    />
                    <Typography align="center" fontWeight="bold" className="md:text-3.5xl text-1.5xl">Gaming
                        Mouse</Typography>
                </div>


            </div>

        </div>
        <Typography align="center" className="w-3/4 md:w-1/3 mx-auto text-center cursor-pointer rounded-md bg-[#0D083E] px-8 py-2 text-white text-sm md:text-lg"
                    onClick={() => {
                        window.location.href = 'https://goodgamingshop.com/catpage';
                    }}
                    // sx={{
                    //     fontWeight: { md: '400' },
                    //     fontSize: { md: '2.5rem', sm: '2rem', xs: '1.5rem' },
                    // }}
        >
            Other
        </Typography>
    </div>

  );
};

export default Topc;
