import React from "react";
import FloatingWhatsAppButton from "../components/FloatingWhatsAppButton";

import Maps from "../assets/contactpage/Group 5.png";

import { Typography } from "@mui/material";

const ContactNew: React.FunctionComponent = () => {
  return (
    <div>
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="mt-10 mb-16 flex flex-col gap-y48 items-center justify-center lg:w-4/5 rounded-lg lg:mx-4">
          <div className="my-6">
            <div className="w-full my-3">
              <h1
                style={{
                  color: "#1A1460",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                className="text-xl md:text-2xl lg:text-3xl font-roboto self-center"
              >
                FIND US AT YOUR NEAREST LOCATION
              </h1>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img src={Maps} alt="" className="w-4/5" />
            <div className="-mt-4 flex bg-[#140F52] rounded-xl p-4">
              <div className="flex  grid-cols-4">
                <div className="flex-col mr-10 ml-5">
                  <p
                    style={{ color: "#ffffff" }}
                    className="mt-2 mb-4 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    Our Location
                  </p>
                  <p
                    style={{ color: "#ffffff", fontWeight: "bold" }}
                    className="my-4 text-l md:text-xl lg:text-xl font-montserrat text-left"
                  >
                    Visit Our Stores
                  </p>
                </div>
                <div className="flex-col mx-5">
                  <p
                    style={{ color: "#ffffff", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-left"
                  >
                    Mangga Dua Mall
                  </p>
                  <p
                    style={{ color: "#ffffff" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    10:00 am to 18:00 pm.
                  </p>
                  <p
                    style={{ color: "#ffffff", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    Ruko Mangga Dua Mall Nomer 45A
                    <br />
                    Jakarta Pusat 10730
                    <br />
                    (+62) 812-6366-5665
                  </p>
                </div>
                <div className="flex-col mx-5">
                  <p
                    style={{ color: "#ffffff", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-left"
                  >
                    Mangga Dua Mall
                  </p>
                  <p
                    style={{ color: "#ffffff" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    10:00 am to 18:00 pm.
                  </p>
                  <p
                    style={{ color: "#ffffff", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    Mangga Dua Mall Lt. 2 Nomer 2A
                    <br />
                    Jakarta Pusat 10730
                    <br />
                    (+62) 812-6366-5665
                  </p>
                </div>
                <div className="flex-col mx-5">
                  <p
                    style={{ color: "#ffffff", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-left"
                  >
                    Serpong
                  </p>
                  <p
                    style={{ color: "#ffffff" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    12:00 am to 20:00 pm.
                  </p>
                  <p
                    style={{ color: "#ffffff", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-left"
                  >
                    Ruko Pisa grande Paramount F/7
                    <br />
                    Tanggerang 15810
                    <br />
                    (+62) 895-349-899-966
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 flex p-4">
              <div className="flex  grid-cols-4">
                <div className="flex-col mx-3">
                  <p
                    style={{ color: "#000000", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-center"
                  >
                    Admin
                  </p>
                  <p
                    style={{ color: "#000000" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    Any problem or something?
                  </p>
                  <p
                    style={{ color: "#ff0000", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    admin@goodgamingshop.com
                  </p>
                </div>
                <div className="flex-col mx-3">
                  <p
                    style={{ color: "#000000", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-center"
                  >
                    Marketing
                  </p>
                  <p
                    style={{ color: "#000000" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    Want to ask our marketing directly?
                  </p>
                  <p
                    style={{ color: "#ff0000", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    marketing@goodgamingshop.com
                  </p>
                </div>
                <div className="flex-col mx-3">
                  <p
                    style={{ color: "#000000", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-center"
                  >
                    Work With Us
                  </p>
                  <p
                    style={{ color: "#000000" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    Want to work with us?
                  </p>
                  <p
                    style={{ color: "#ff0000", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    hrd@goodgamingshop.com
                  </p>
                </div>
                <div className="flex-col mx-3">
                  <p
                    style={{ color: "#000000", fontWeight: "bold" }}
                    className="my-2 text-l md:text-lg lg:text-lg font-montserrat text-center"
                  >
                    Aftersales/RMA
                  </p>
                  <p
                    style={{ color: "#000000" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    Have a complaint with our product?
                  </p>
                  <p
                    style={{ color: "#ff0000", fontWeight: "semi-bold" }}
                    className="my-2 text-md md:text-l lg:text-l font-montserrat text-center"
                  >
                    Whatsapp : +628984884665
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default ContactNew;
