import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay, Navigation]);

const Banner = () => {
  const [data, setData] = useState<any[]>([]);
  const handleGetBrandList = async () => {
    try {
      const result = await axios.get(
        "https://goodgamingshop.com/api/v1/banners"
      );
      const data = result.data.data;
      setData(data.filter((item: any) => item.id < 8));
    } catch (err) {
      console.log(err);
    }
  };

  const handleNavigate = (img: any) => {
    if (img.redirect_url) {
      window.open(img.redirect_url, "_blank");
    } else {
      window.location.href = "#";
    }
  };

  useEffect(() => {
    handleGetBrandList();
  }, []);

  return (
    <div className="flex items-center justify-center w-90 mt-4">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        effect={"coverflow"}
        grabCursor={false}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        navigation={true}
        className="mySwiper rounded-xl hover:cursor-pointer"
      >
        {data.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={img.image} alt="" onClick={() => handleNavigate(img)} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Banner;
