import React, {useEffect, useState} from 'react';
import axios from 'axios';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';


import Banner from '../components/Banner';
//import AboutUs from '../components/AboutUs';
import BrandCard from '../components/Brandcard';
import StaticBanner from '../components/staticBanner';
import CategoriCard from '../components/CategoryCard';
import RecommendedCard from '../components/RecommendedProduct';
import NewestProductCard from '../components/NewestProductCard';
import DiscountedProductCard from '../components/DiscountedProduct';
import BannerLink from '../components/BannerLink';
import Topc from '../components/Topc';
import Barisbannera from "../components/BarisBannera";
import Barisbannerb from "../components/BarisBannerb";
import BrandSlider from "../components/BrandSlider";

const Home: React.FunctionComponent = () => {
  const [data, setData] = useState<any[]>([]);

  const handleGetBanners = async () => {
    try {
      const result = await axios.get('https://goodgamingshop.com/api/v1/banners');
      const data = result.data.data;
      setData(data.filter((item: any) => item.id >= 8));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetBanners();
  }, []);
  return (
      <div className='flex flex-col gap-10 items-center justify-center mb-16'>
          <Banner/>

          <Topc/>

          <Barisbannerb/>
          <NewestProductCard/>

          <BannerLink/>
          <BrandSlider/>

          <DiscountedProductCard/>

          {/* <StaticBanner banner={data[0]?.image} /> */}
          <Barisbannera/>


          <RecommendedCard/>

          <FloatingWhatsAppButton/>
          <div className="container mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <div className="relative pb-[56.25%] h-0 overflow-hidden">
                      <iframe
                          className="absolute top-0 left-0 w-full h-full"
                          src="https://www.youtube.com/embed/7eCb0OBy0aY"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                      ></iframe>
                  </div>
                  <div className="relative pb-[56.25%] h-0 overflow-hidden">
                      <iframe
                          className="absolute top-0 left-0 w-full h-full"
                          src="https://www.youtube.com/embed/sHZii3kh6x0"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                      ></iframe>
                  </div>
                  <div className="relative pb-[56.25%] h-0 overflow-hidden">
                      <iframe
                          className="absolute top-0 left-0 w-full h-full"
                          src="https://www.youtube.com/embed/NkCfyYraLmI"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                      ></iframe>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Home;
