import React from 'react';
import Logowa from "../assets/logowa.png";

const FloatingWhatsAppButton: React.FC = () => {
    return (
      <div className="mb-6 md:mb-4  pr-2  fixed bottom-16 justify-end right-4  transition-all duration-300 max-w-xs md:max-w-xl">
  <a
    href="https://wa.link/569hrm"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={Logowa} alt="Gambar 1" className="w-1/2 md:w-3/5 mr-2 float-right" />
  </a>
</div>

    );
  };
  
  export default FloatingWhatsAppButton;
  