import React from "react";
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

import R24 from "../assets/returwar/24.webp";
import Puzzle from "../assets/returwar/puzzle 2.webp";
import New from "../assets/returwar/new 1.webp";
import Del from "../assets/returwar/delivery-truck 1.webp";
import Set from "../assets/returwar/settings 1.webp";
import Qua from "../assets/returwar/quality 1.webp";
import Time from "../assets/returwar/time-zone 1.webp";
import Loc from "../assets/returwar/location 1.webp";





import { Typography } from "@mui/material";

const Warran: React.FunctionComponent = () => {
  return (
    <div>
    <div className="flex flex-col   gap-10 items-center justify-center">
    <div className="my-6 mx-6 md:mx-8">
        <div className="flex flex-col px-2 md:px-16">
            <div className="w-full  px-8 my-3">
                    <h1 style={{ color: '#1A1460' , textAlign: 'left',fontWeight: 'bold' }} className="text-xl md:text-2xl lg:text-3xl font-roboto self-center">
                    Return & Warranty
                    </h1>
                </div>
                <div className="w-full px-8 my-3">
                    <h1 style={{ color: '#000000' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat text-left">
                    Semua barang yang dikirim dari GOODGAMINGSHOP masih ada segelnya, kecuali jika kamu ingin kita mengecek barangnya terlebih dahulu sebelum dikirim. Gaming gear yang bisa digaransi hanya gaming gear yang memang ada problem dari factory, bukan karena human error seperti kesiram air, terbakar, tergigit, dent yang disebabkan karena jatuh, dan sebagainya.
                    </h1>
                </div>
            </div>
        </div>
        <div className="flex flex-col md:flex-row  gap-10  items-center  w-3/4">
            <div className="flex grow flex-col border border-gray-500   mx-2 md:mx-6 p-4 rounded-lg w-full md:w-1/2 h-[730px]">
                <div className="w-full  my-3">
                    <h1 style={{ color: '#1A1460' , textAlign: 'center',fontWeight: 'bold' }} className="text-xl md:text-2xl lg:text-3xl font-roboto self-center">
                    Ketentuan Return
                    </h1>
                </div>
                <div className="flex-auto justify-between">
                    <div className="flex flex-row items-center  gap-2 md:gap-4">
                        <div className="flex-none w-1/6">
                            <img src={R24} alt="" className="w-full self-center" />
                        </div>
                        <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Barang rusak dapat dikembalikan 1x24 jam, dengan syarat video unboxing
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row items-center  gap-2 md:gap-4">
                        <div className="flex-none   py-8  w-1/6">
                            <img src={Puzzle} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Kelengkapan barang yang di return harus sama persis dengan awal barang diterima
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row items-center  gap-2 md:gap-4">
                        <div className="flex-none   py-8  w-1/6">
                            <img src={New} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Kondisi barang harus masih baru dan belum terpakai
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row items-center  gap-2 md:gap-4">
                        <div className="flex-none   py-8  w-1/6">
                            <img src={Del} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Barang akan dikirim setelah melakukan pengecekan oleh kami
                            </h1>     
                        </div>
                    </div>
                    

                </div>


            </div>
            <div className="flex flex-grow flex-col border border-gray-500 mx-2 md:mx-6 p-4 rounded-lg w-full md:w-1/2 h-[730px]">
                
                <div className="w-full my-3">
                    <h1 style={{ color: '#1A1460' , textAlign: 'center',fontWeight: 'bold' }} className="text-xl md:text-2xl lg:text-3xl font-roboto self-center">
                    Ketentuan Warranty
                    </h1>
                </div>
                <div className="flex flex-col justify-between">
                    <div className="flex flex-row    gap-2 md:gap-4">
                        <div className="flex-none     py-8  w-1/6">
                            <img src={Set} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Problem bukan bersifat technical, kerusakan fisik dan Human Error
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row    gap-2 md:gap-4">
                        <div className="flex-none     py-8  w-1/6">
                            <img src={Qua} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Kebijakan garansi sesuai dengan kebijakan dari masing-masing brand dan distributor 
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row    gap-2 md:gap-4">
                        <div className="flex-none     py-8  w-1/6">
                            <img src={Time} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Lama proses garansi tergantung dari distributor resmi dari gear yang kamu miliki
                            </h1>     
                        </div>
                    </div>
                    <div className="flex flex-row    gap-2 md:gap-4">
                        <div className="flex-none     py-8  w-1/6">
                            <img src={Loc} alt="" className="w-full self-center" />
                        </div>
                          <div className="w-5/6 self-center  items-center pr-8">
                            
                            <h1 style={{ color: '#000000', textAlign: 'left' }} className="my-6 text-l md:text-xl lg:text-xl font-montserrat">
                            Garansikan gearmu dengan menghubungi admin garansi GOODGAMINGSHOP 
( 0898 – 4884 – 665 )
                            </h1>     
                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
    <FloatingWhatsAppButton /> 
    </div>
  );
};

export default Warran;
