import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

type ProductType = {
  id: number;
  name: string;
  SKU: string;
  price: number;
  tokopedia_link: string;
  shopee_link: string;
  bukalapak_link: string;
  description: string;
  brand: { name: string };
  specification: {
    id: number;
    json_data: {};
  };
  category: {
    id: number;
    name: string;
    specification: any[];
  };
  subcategory: {
    id: number;
    name: string;
    category_id: number;
  };
  images: [
    {
      id: number;
      url: string;
      product_id: number;
    }
  ];
  variants: {
    id: number;
    variant_data: [
      {
        final_price: string;
        initial_price: string;
        name: string;
        price: number;
      }
    ];
  };
};

const initialState: ProductType = {
  id: 15,
  name: "test 10",
  SKU: "123",
  price: 30000,
  tokopedia_link:
    "https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition",
  shopee_link:
    "https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition",
  bukalapak_link:
    "https://www.goodgamingshop.com/?product=tiger-arc-long-teng-special-edition",
  brand: { name: "" },
  description: "",
  specification: {
    id: 16,
    json_data: {
      tipe: "tipe",
      model: "model",
      socket: "socke",
    },
  },
  category: {
    id: 11,
    name: "VGA",
    specification: ["model", "tipe", "socket"],
  },
  subcategory: {
    id: 2,
    name: "AMD",
    category_id: 11,
  },
  images: [
    {
      id: 7,
      url: "https://storage.googleapis.com/ggs-dewa-ruci/aea4a2d0-8088-4e5c-8323-f023c8acc2bc.webp",
      product_id: 15,
    },
  ],
  variants: {
    id: 16,
    variant_data: [
      {
        final_price: "1.986.000",
        initial_price: "2.648.000",
        name: "Black",
        price: 1986000,
      },
    ],
  },
};

const Product: React.FunctionComponent = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<ProductType>(initialState);

  const CartFromLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]");
  const [cart, setCart] = useState<any>(CartFromLocalStorage);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleGetProduct = async () => {
    try {
      const response = await axios.get(
        `https://goodgamingshop.com/api/v1/product/${id}`
      );
      const data = response.data.data;
      setProduct(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getTotalQuantity = () => {
    return cart.reduce((acc: any, item: any) => acc + item.quantity, 0);
  };

  const getFinalPrice = () => {
    return cart.reduce(
      (acc: any, item: any) => acc + item.finalPrice * item.quantity,
      0
    );
  };

  const getTotalPrice = () => {
    return cart.reduce(
      (acc: any, item: any) =>
        acc +
        parseInt(item.initialPrice.replace(/\./g, ""), 10) * item.quantity,
      0
    );
  };

  const handleAddQuantity = (index: any) => {
    const newCart = [...cart];
    newCart[index].quantity += 1;
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleMinusQuantity = (index: any) => {
    const newCart = [...cart];
    if (newCart[index].quantity > 1) {
      newCart[index].quantity -= 1;
      setCart(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart));
    }
  };

  const getTotalDiscount = () => {
    return cart.reduce(
      (acc: any, item: any) =>
        acc +
        (parseInt(item.initialPrice.replace(/\./g, ""), 10) * item.quantity -
          item.finalPrice * item.quantity),
      0
    );
  };

  const handleRemoveCartItem = (index: any) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    enqueueSnackbar("Removed From Cart", { variant: "error" });
  };

  useEffect(() => {
    handleGetProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mb-10 w-full lg:w-full">
      <div className="h-full bg-gray-100 pt-10 items-center">
        <h1 className="mb-10 ml-28 text-2xl font-bold">Shopping Cart</h1>
        <div className="mx-auto max-w-5xl justify-center lg:px-6 lg:flex lg:space-x-6 xl:px-0">
          <div className="rounded-lg lg:w-2/3">
            <div className="flex justify-start items-center p-6 md:flex md:justify-start">
              <input type="checkbox" className="w-5 h-5 mr-5" value="id-1" />
              <h4 className="text-base font-medium text-gray-700">
                Pilih Semua
              </h4>
            </div>
            <hr className="border" />
            <div className="justify-between items-center rounded-lg p-6 md:flex md:justify-start lg:flex-col">
              {cart.map((item: any, index: any) => {
                return (
                  <div className="flex mb-4 w-full items-center bg-white rounded-lg p-1">
                    <div>
                      <input
                        type="checkbox"
                        className="w-5 h-5 mr-5"
                        value="id-1"
                      />
                    </div>
                    <img
                      src={item.image}
                      alt="product"
                      className="w-[69px] h-[69px] rounded-lg lg:w-40 lg:h-full"
                    />
                    <div className="lg:ml-4 lg:flex lg:w-full lg:justify-between">
                      <div className="mt-0 ml-2 lg:mt-5">
                        <div className="hover: cursor-pointer">
                          <p
                            className="text-sm font-medium text-gray-700 lg:text-base"
                            onClick={() => navigate(`/product/${item.id}`)}
                          >
                            {item.name}
                          </p>
                        </div>
                        <p className="text-xs font-medium text-gray-700">
                          {item.variant}
                        </p>
                        <div className="flex mt-3">
                          {item.discount !== 0 && item.discount !== null ? (
                            <div className="bg-info-20 p-1 rounded-sm text-xs w-fit h-fit text-white flex">
                              {item.discount}%
                            </div>
                          ) : null}
                          {item.discount !== 0 && item.discount !== null ? (
                            <span className="text-primary-10 ml-2">
                              <s>Rp {item.initialPrice} </s>
                            </span>
                          ) : null}
                          <span className="text-primary-10 ml-2">
                            Rp{" "}
                            {item.finalPrice
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </span>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                        <div className="flex items-center border-gray-100">
                          <span
                            className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"
                            onClick={() => {
                              handleMinusQuantity(index);
                            }}
                          >
                            -
                          </span>
                          <input
                            className="h-8 w-8 border bg-white text-center text-xs outline-none"
                            type="number"
                            value={item.quantity < 0 ? 0 : item.quantity}
                            min="1"
                          />
                          <span
                            className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"
                            onClick={() => {
                              handleAddQuantity(index);
                            }}
                          >
                            +
                          </span>
                          <button onClick={() => handleRemoveCartItem(index)}>
                            <svg
                              className="w-8 h-8 hover:text-blue-600 rounded-full hover:bg-gray-100 p-1"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-6 h-full rounded-lg border mx-1 lg:mx-0 bg-white p-6 lg:mt-0 lg:w-1/3">
            <div className="flex justify-between">
              <p className="text-gray-700 font-medium text-xl">
                Ringkasan Belanja
              </p>
            </div>
            <hr className="my-4" />
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Jumlah Barang</p>
              <p className="text-gray-700">{getTotalQuantity()}</p>
            </div>
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Subtotal</p>
              <p className="text-gray-700">
                Rp{" "}
                {getTotalPrice()
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-700">Diskon</p>
              <p className="text-gray-700">
                Rp{" "}
                {getTotalDiscount()
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold">Total</p>
              <div className="">
                <p className="mb-1 text-lg font-bold">
                  Rp{" "}
                  {getFinalPrice()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </p>
              </div>
            </div>
            <button
              className="mt-6 w-full rounded-md bg-info-20 py-1.5 font-medium text-blue-50 hover:bg-blue-600"
              onClick={() => navigate("/checkout")}
            >
              Check out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
