import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

import { product1 } from "../assets/productImage";

const productz = [product1, product1, product1, product1, product1];

export default function ProductCarousel({ productImage }: any) {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | any>(null);

  return (
    <div className="w-80">
      <Swiper
        style={
          {
            // '--swiper-navigation-color': '#fff',
            // '--swiper-pagination-color': '#fff',
          }
        }
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {productImage
          ? productImage.map((image: string | undefined) => {
              return (
                <SwiperSlide>
                  <img src={image} alt="" />
                </SwiperSlide>
              );
            })
          : productz.map((image: string | undefined) => {
              return (
                <SwiperSlide>
                  <img src={image} alt="" />
                </SwiperSlide>
              );
            })}
      </Swiper>
      <Swiper
        // onSwiper={(swiper) => setThumbsSwiper(swiper)}
        loop={true}
        spaceBetween={10}
        slidesPerView={5}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {productImage
          ? productImage.map((image: string | undefined) => {
              return (
                <SwiperSlide>
                  <img src={image} alt="" />
                </SwiperSlide>
              );
            })
          : productz.map((image: string | undefined) => {
              return (
                <SwiperSlide>
                  <img src={image} alt="" />
                </SwiperSlide>
              );
            })}
      </Swiper>
    </div>
  );
}
