import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useSnackbar} from 'notistack';

import {Typography} from '@mui/material';
import {ContentCopy} from '@mui/icons-material';

const SuccessPage: React.FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  return (
    <div className='bg-success h-screen bg-no-repeat bg-cover'>
      <div className='bg-black bg-opacity-60 h-screen w-screen flex justify-center'>
        <div className='flex flex-col items-center justify-center gap-16'>
          <Typography
            sx={{color: 'white'}}
            variant='h2'
          >
            Thank you for your order.
          </Typography>
          <div className='flex flex-col gap-4 items-center justify-center'>
            <Typography
              sx={{color: 'white'}}
              variant='h5'
            >
              You can check your order with this Order Code :
            </Typography>
            <div className='text-white flex gap-4'>
              <Typography
                sx={{color: 'white'}}
                variant='h4'
              ></Typography>
              {id}
              <div
                className='hover:cursor-pointer'
                onClick={() => {
                  navigator.clipboard.writeText(String(id));
                  enqueueSnackbar('Copied to clipboard', {variant: 'success'});
                }}
              >
                <ContentCopy />
              </div>
            </div>
          </div>
          <div className='flex gap-8 w-full items-center justify-center'>
            <button
              className='mt-6 w-1/3 h-full rounded-md bg-info-20 py-1.5 font-medium text-blue-50 hover:bg-blue-600'
              onClick={() => navigate('/')}
            >
              Back to Shopping
            </button>
            <button
              className='mt-6 w-1/3 h-full rounded-md border-white border-2 py-1.5 font-medium text-blue-50 hover:border-blue-600 hover:text-blue-600'
              onClick={() => navigate('/orders')}
            >
              Check Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
