import React from "react";

import Build from "../assets/build.jpg";
import Pc from "../assets/pc.jpg";
import Consign from "../assets/consign.jpg";

import { Typography } from "@mui/material";

const BannerLink: React.FunctionComponent = () => {
  return (
    <div className="mb-4 flex flex-col gap-y-8 lg:flex-row items-center justify-between lg:w-4/5 rounded-lg lg:mx-4">
  <div className="w-full">
    <img
      src={Build}
      alt="Gambar 1"
      className="w-full px-3 rounded-xl cursor-pointer"
      onClick={() => {
        window.location.href = 'https://keebcorner.com/';
      }}
    />
  </div>
  <div className="w-full">
    <img
      src={Pc}
      alt="Gambar 2"
      className="w-full px-3 rounded-xl cursor-pointer"
      onClick={() => {
        window.location.href = 'https://api.whatsapp.com/send/?phone=%2B628981955665&text&app_absent=0';
      }}
    />
  </div>
  <div className="w-full">
    <img
      src={Consign}
      alt="Gambar 3"
      className="w-full px-3 rounded-xl cursor-pointer"
      onClick={() => {
        window.location.href = 'https://www.instagram.com/ggsconsign/';
      }}
    />
  </div>
</div>




  );
};

export default BannerLink;
