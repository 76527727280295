import React, { useEffect, useState } from 'react';
import axios from 'axios';


import CategoriCard from '../components/CategoryCard';

const CatPage: React.FunctionComponent = () => {
  const [data, setData] = useState<any[]>([]);

  return (
    <div className='flex flex-col gap-10 items-center justify-center'>
      <CategoriCard />
      
    </div>
  );
};

export default CatPage;

