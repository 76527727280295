import React from "react";

import B1 from "../assets/bannerbaru/banner1.webp";
import B2 from "../assets/bannerbaru/Mouse Under 500K_Mini Banner.webp";
import B3 from "../assets/bannerbaru/Noir Neo C_Mini Banner.webp";
import B4 from "../assets/bannerbaru/Monsgeek M1 & M2_Mini Banner.webp";



import { Typography } from "@mui/material";
import { banner1 } from "../assets/staticBanner";

const BarisBannerb: React.FunctionComponent = () => {
  return (
    
    <div className="flex flex-col items-center justify-center w-4/5 ">
        
        <div className="flex flex-col md:flex-row w-full mt-2  ">
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 mb-2 px-2 justify-center">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar1.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/1';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar2.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/2';
                      }} />
          </div>
          <div className="flex flex-row w-full md:w-1/2 gap-x-2 px-2 justify-center  ">
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar3.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/3';
                      }} />
          <img src="https://cmsbanner.goodgamingshop.com/assets/gambar4.webp" alt="" className="w-1/2" onClick={() => {
                          window.location.href = 'https://cmsbanner.goodgamingshop.com/redirect/4';
                      }} />
          </div>
        </div>
        

            
            
    </div>
    

  );
};

export default BarisBannerb;
